<template>
  <div>
    <div
      class="modal fade z-index-1052"
      id="modalTemplate"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTemplate"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-flat-gray-2">
            <h5 class="modal-title text-dark-blue" id="vizualizarPerfilLabel">
              {{ $t("label.perfilDoCandidato") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="modal-body bg-flat-gray"
            v-if="candidate.candidate.fullName != ''"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="perfil-image float-left mb-3 mr-4">
                  <img
                    :src="
                      jobCandidate
                        ? jobCandidate.candidate
                          ? jobCandidate.candidate.image
                          : ''
                        : ''
                    "
                    :alt="$t('label.fotoPerfil')"
                  />
                </div>
                <div class="float-left">
                  <h4 class="text-left">
                    {{ candidate.candidate.fullName }}
                  </h4>
                  <p class="text-left mb-0">
                    <span class="small text-left text-dark-blue"
                      >{{ $t("label.email") }}:</span
                    >
                    {{ candidate.candidate.mail }} /
                    <span class="small text-left text-dark-blue"
                      >{{ $t("label.telefone") }}:</span
                    >
                    {{ candidate.candidate.phone }}
                  </p>
                  <h6 class="text-left mt-2">
                    {{ $t("label.cargoDesejado") }}:
                    <span class="small text-muted text-left">{{
                      candidate.candidate.position
                    }}</span>
                  </h6>
                  <h6 class="text-left mt-2">
                    {{ $t("label.endereco") }}:
                    <span class="small text-muted text-left">
                      {{ candidate.candidate.address }},
                      {{ candidate.candidate.number }},
                      {{ candidate.candidate.neighborhood }} -
                      {{ candidate.candidate.cep }}
                    </span>
                  </h6>
                  <h6 class="text-left mt-2">
                    {{ $t("label.cidade") }} / {{ $t("label.uf") }}:
                    <span class="small text-muted text-left">
                      {{ candidate.candidate.city }} /
                      {{ candidate.candidate.state }}
                    </span>
                  </h6>
                  <h6 class="text-left mt-2">
                    {{ $t("label.pcd") }}:
                    <span class="small text-muted text-left">{{
                      candidate.candidate.special_condition == 1
                        ? $t("label.sim")
                        : $t("label.nao")
                    }}</span>
                  </h6>
                  <h6 class="text-left mt-2">
                    Última atualização do currículo:
                    <span class="small text-muted text-left">{{
                      candidate.candidate.updated_at
                    }}</span>
                  </h6>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-12">
                <h4 class="text-center">Avaliação Inteligência Artificial</h4>
                <b>Pontuação:</b> {{candidate.candidate.score}}
                <br>
                <b>Motivo:</b> {{candidate.candidate.ia_description}}
              </div>
            </div>
            <hr>
            <ul
              class="nav nav-pills nav-justified filters mb-3"
              id="pills-tab-perfil"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-curriculo-tab"
                  data-toggle="pill"
                  href="#curriculo"
                  role="tab"
                  aria-controls="pills-curriculo"
                  aria-selected="true"
                  @click="changeTab('curriculo')"
                >
                  <i class="fa fa-file-text-o"></i>
                  {{ $t("label.curriculo") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-comentarios-tab"
                  data-toggle="pill"
                  href="#comentarios"
                  role="tab"
                  aria-controls="pills-comentarios"
                  aria-selected="true"
                  @click="changeTab('comentarios')"
                >
                  <i class="fa fa-comments-o"></i>
                  {{ $t("label.comentarios") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-teste-tab"
                  data-toggle="pill"
                  href="#teste"
                  role="tab"
                  aria-controls="pills-teste"
                  aria-selected="true"
                  @click="changeTab('teste')"
                >
                  <i class="fa fa-list-alt"></i>
                  {{ $t("label.teste") }}
                </a>
              </li>
            </ul>
            <div class="tab-content" id="modalTabContent">
              <div
                class="tab-pane fade show active"
                id="curriculo"
                role="tabpanel"
                aria-labelledby="pills-curriculo-tab"
              >
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="text-justify mb-2">{{ $t("label.resumo") }}</h5>
                    <p class="text-justify mb-1">
                      {{ candidate.candidate.summary }}
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-12">
                    <div id="accordion">
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target-id="#collapseExperiencia"
                            aria-expanded="false"
                            aria-controls="collapseExperiencia"
                          >
                            {{ $t("label.experienciaProfissional") }}
                          </button>
                        </div>
                        <div
                          id="collapseExperiencia"
                          class="collapse"
                          aria-labelledby="headingExperiencia"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="experience in candidate.experiences"
                              :key="experience.id"
                            >
                              <h5 class="text-left mb-1">
                                {{ experience.position }} -
                                {{ experience.company }}
                              </h5>
                              <p class="small text-muted text-left mb-2">
                                <span
                                  >{{ $t("label.de") }}
                                  {{ experience.initial_date }}
                                  {{ $t("label.a") }}
                                  {{ experience.end_date }}</span
                                >
                              </p>
                              <p class="text-justify mb-0">
                                {{ experience.description }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseEducacao"
                            aria-expanded="false"
                            aria-controls="collapseEducacao"
                          >
                            {{ $t("label.educacao") }}
                          </button>
                        </div>
                        <div
                          id="collapseEducacao"
                          class="collapse"
                          aria-labelledby="headingEducacao"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="graduation in candidate.graduations"
                              :key="graduation.id"
                            >
                              <h5 class="text-left mb-1">
                                {{ graduation.institution }}
                              </h5>
                              <p class="small text-muted text-left mb-2">
                                <span
                                  >{{ $t("label.de") }}
                                  {{ graduation.period }}</span
                                >
                              </p>
                              <p class="text-justify mb-0">
                                {{ $t("label.graduacaoEm") }}
                                {{ graduation.course }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseIdiomas"
                            aria-expanded="false"
                            aria-controls="collapseIdiomas"
                          >
                            {{ $t("label.idiomas") }}
                          </button>
                        </div>
                        <div
                          id="collapseIdiomas"
                          class="collapse"
                          aria-labelledby="headingIdiomas"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="language in candidate.languages"
                              :key="language.id"
                            >
                              <h5 class="text-left mb-0">
                                {{ language.description }}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseCursos"
                            aria-expanded="false"
                            aria-controls="collapseCursos"
                          >
                            {{ $t("label.cursos") }}
                          </button>
                        </div>
                        <div
                          id="collapseCursos"
                          class="collapse"
                          aria-labelledby="headingCursos"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="course in candidate.courses"
                              :key="course.id"
                            >
                              <h5 class="float-left mb-0">
                                {{ course.description }}
                              </h5>
                              <p class="small text-muted float-right mb-0">
                                <span>{{ course.institution }}</span>
                              </p>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseCurriculo"
                            aria-expanded="false"
                            aria-controls="collapseCurriculo"
                          >
                            {{ $t("label.curriculoAnexo") }}
                          </button>
                        </div>
                        <div
                          id="collapseCurriculo"
                          class="collapse"
                          aria-labelledby="headingCurriculo"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div class="parts-divider">
                              <h5 class="float-left mb-0">
                                <a :href="candidate.candidate.curriculum_doc">
                                  Download
                                  <i class="fa fa-download"></i>
                                </a>
                              </h5>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseConhecimentos"
                            aria-expanded="false"
                            aria-controls="collapseConhecimentos"
                          >
                            {{ $t("label.conhecimentos") }}
                          </button>
                        </div>
                        <div
                          id="collapseConhecimentos"
                          class="collapse"
                          aria-labelledby="headingConhecimentos"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="skill in candidate.skills"
                              :key="skill.id"
                            >
                              <h5 class="float-left mb-0">
                                {{ skill.description }}
                              </h5>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card" v-if="candidate.networks.length > 0">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseRedesSociais"
                            aria-expanded="false"
                            aria-controls="collapseRedesSociais"
                          >
                            {{ $t("label.redesSociais") }}
                          </button>
                        </div>
                        <div
                          id="collapseRedesSociais"
                          class="collapse"
                          aria-labelledby="headingRedesSociais"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="network in candidate.networks"
                              :key="network.id"
                            >
                              <h5 class="float-left mb-0">
                                {{ network.name }} -
                                <a :href="network.link" target="_brank">{{
                                  network.link
                                }}</a>
                              </h5>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade show"
                id="comentarios"
                role="tabpanel"
                aria-labelledby="pills-comentarios-tab"
              >
                <form action method="post" class>
                  <div class="form-group mt-2">
                    <label for="anotacoes" class="control-label mb-1">{{
                      $t("label.comentarios")
                    }}</label>
                    <div class="input-group">
                      <div class="input-group-addon">
                        <i class="fa fa-file-text-o"></i>
                      </div>
                      <textarea
                        v-model="content"
                        name="anotacoes"
                        id="anotacoes"
                        rows="3"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                </form>
                <input
                  type="checkbox"
                  v-if="is_manager"
                  value="1"
                  v-model="visibility_requester"
                  checked
                />
                <span v-if="is_manager"
                  >Não exibir comentário para o gestor</span
                >
                <button
                  @click="newIteration"
                  class="btn edit-btn float-right"
                  type="button"
                >
                  <i class="fa fa-floppy-o"></i>
                  {{ $t("label.salvar") }}
                </button>
                <div class="clear"></div>
                <hr />
                <div
                  v-if="candidate.iterations.length == 0"
                  class="alert alert-warning text-center"
                >
                  {{ $t("label.nenhumComentarioParaExibir") }}
                </div>
                <div
                  class="comments"
                  v-for="(item, index) in candidate.iterations"
                  :key="index"
                >
                  <template
                    v-if="
                      $store.getters.user.id == item.user_id ||
                      id_requester == item.user_id ||
                      ($store.getters.user.user_type_id != 2 &&
                        item.visibility_requester == 0)
                    "
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div
                          class="perfil-image-2 float-left mr-4"
                          v-if="
                            $store.getters.mode != 'web' &&
                            item.user.image_fluig != '#'
                          "
                        >
                          <img :src="item.user.image_fluig" alt="Foto Perfil" />
                        </div>
                        <div class="float-left">
                          <p class="text-left text-dark-blue mb-2">
                            {{ item.user.name }}
                            <span class="small text-muted"
                              >{{ $t("label.em") }}
                              {{
                                new Date(item.created_at).toLocaleDateString()
                              }}
                              {{ $t("label.aas") }}
                              {{
                                new Date(item.created_at).toLocaleTimeString()
                              }}</span
                            >
                          </p>
                          <p class="text-left mb-0">{{ item.content }}</p>
                        </div>
                        <div class="float-right">
                          <span class="badge badge-pill badge-info">{{
                            item.jobstage.name
                          }}</span>
                        </div>
                        <div
                          class="float-right"
                          v-if="is_manager && item.user.user_type_id == 2"
                        >
                          <span
                            class="badge badge-pill badge-secondary"
                            v-if="item.visibility_requester == 1"
                            >{{ $t("label.naoVisivelAoGestor") }}</span
                          >
                          <span
                            class="badge badge-pill badge-success"
                            v-if="item.visibility_requester == 0"
                            >{{ $t("label.visivelAoGestor") }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <hr />
                  </template>
                </div>
              </div>
              <div
                class="tab-pane fade show"
                id="teste"
                role="tabpanel"
                aria-labelledby="pills-comentarios-tab"
              >
                <div
                  v-if="questions.length == 0"
                  class="alert alert-warning text-center"
                >
                  {{ $t("label.candidatoAindaNaoRespondeuTeste") }}
                </div>
                <div class="row" v-if="questions.length > 0">
                  <div class="col-md-12">
                    <div id="accordionQuestions" class="accordion">
                      <div
                        class="card"
                        v-for="(question, index) in questions"
                        :key="index"
                      >
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            :data-target-id="'#collapse' + index"
                            aria-expanded="false"
                            :aria-controls="'collapse' + index"
                          >
                            {{ question.name }}
                          </button>
                        </div>
                        <div
                          :id="'collapse' + index"
                          class="collapse"
                          :aria-labelledby="'heading' + index"
                          data-parent="#accordionQuestions"
                        >
                          <div class="card-body">
                            <div class="row mb-3">
                              <div class="col-md-12">
                                <h6 class="float-left">
                                  {{ $t("label.recebidoEm") }}:
                                  <span class="small text-muted text-left">{{
                                    new Date(
                                      question.created_at
                                    ).toLocaleDateString() +
                                    " - " +
                                    new Date(
                                      question.created_at
                                    ).toLocaleTimeString()
                                  }}</span>
                                </h6>
                                <h6 class="float-right">
                                  {{ $t("label.finalizadoEm") }}:
                                  <span class="small text-muted text-left">{{
                                    new Date(
                                      question.finalized_date
                                    ).toLocaleDateString() +
                                    " - " +
                                    new Date(
                                      question.finalized_date
                                    ).toLocaleTimeString()
                                  }}</span>
                                </h6>
                              </div>
                            </div>

                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">
                                    {{ $t("label.pergunta") }}
                                  </th>
                                  <th scope="col">
                                    {{ $t("label.resposta") }}
                                  </th>
                                  <th scope="col" class="text-center">
                                    {{ $t("label.status") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(answer, index_a) in question.answers"
                                  :key="index_a"
                                >
                                  <th scope="row">{{ index_a + 1 }})</th>
                                  <td>{{ answer.question }}</td>
                                  <td>{{ answer.answer }}</td>
                                  <td>
                                    <p class="badge-field text-center">
                                      <span
                                        class="badge bg-green text-light-gray"
                                        v-if="answer.correct == 1"
                                        >{{ $t("label.correta") }}</span
                                      >
                                    </p>
                                    <p class="badge-field text-center">
                                      <span
                                        class="badge bg-red text-light-gray"
                                        v-if="answer.correct == 0"
                                        >{{ $t("label.incorreta") }}</span
                                      >
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-flat-gray-2">
            <button type="button" class="btn delete-btn" data-dismiss="modal">
              {{ $t("label.fechar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "candidate",
    "stages",
    "job_id",
    "url_iteration",
    "is_manager",
    "id_requester",
    "jobCandidateID",
  ],
  data: function () {
    let self = this;
    return {
      tableName: "messageTable",
      columns: ["name", "actions"],
      tableData: [],
      options: {
        headings: {
          name: self.$t("label.nome"),
          actions: self.$t("label.acoes"),
        },
        debounce: 1000,
      },
      observations: [],
      msg_header: "",
      msg_text: "",
      people: 0,
      observation: "",
      url: "/",
      content: "",
      visibility_requester: 1,
      iterations: [],
    };
  },
  computed: {
    jobCandidate: function () {
      return this.candidate;
    },
    questions: function () {
      let answers = this.candidate.answers;

      let questionnaire_id = "";
      let questionnaire = [];
      for (let j in answers) {
        if (questionnaire_id != answers[j].question.questionnaire.id) {
          questionnaire_id = answers[j].question.questionnaire.id;

          let passport = this.candidate.passports.filter(
            (passport) => passport.questionnaire_id == questionnaire_id
          )[0];

          questionnaire.push({
            name: answers[j].question.questionnaire.name,
            finalized_date: answers[j].created_at,
            created_at: passport.created_at,
            answers: [],
          });
        }

        questionnaire[questionnaire.length - 1].answers.push({
          question: answers[j].question.description,
          answer: answers[j].answer.description,
          correct: answers[j].correct,
        });
      }

      return questionnaire;
    },
  },
  methods: {
    changeTab(tab) {
      $(".nav-item .nav-link").each(function () {
        $(this).removeClass("active show");
      });
      $(".tab-content .tab-pane").each(function () {
        $(this).removeClass("active show");
      });

      $("#pills-" + tab + "-tab").addClass("active show");
      $("#" + tab).addClass("active show");
    },
    newIteration: function () {
      const self = this;

      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .post(this.url_iteration, {
          content: self.content,
          visibility_requester: self.visibility_requester,
          job_id: self.job_id,
          job_stage_id: self.candidate.status_id,
          job_candidate_stage_id: self.candidate.id,
          job_candidate_id: self.candidate.job_candidate_id,
          user_id: self.$store.getters.user.id,
        })
        .then(() => {
          self.$store.dispatch("tooggleLoadModal");
          self.content = "";
          self.loaditerations();
          self.$message(
            "success",
            self.$t("label.sucesso"),
            self.$t("label.comentarioAdicionadoComSucesso")
          );
        })
        .catch((error) => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response
            ? error.response.data
            : self.$t("label.ocorreuUmErroAoRealizarUmaInteracao");

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            $.each(errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
    loaditerations: function () {
      let self = this;
      self.$store.dispatch("tooggleLoadModal");
      this.$http
        .get(this.url_iteration, {
          params: {
            job_candidate_id: self.candidate.job_candidate_id,
            job_id: self.job_id,
            paginated: false,
            with: ["user", "jobstage"],
          },
        })
        .then((response) => {
          self.$store.dispatch("tooggleLoadModal");
          self.candidate.iterations = response.data.data.reverse();
          $("#modalTemplate").modal("show");
        })
        .catch((error) => {
          self.$store.dispatch("tooggleLoadModal");
          let errors = error.response
            ? error.response.data
            : self.$t("label.ocorreuUmErroAoRealizarUmaInteracao");

          if (typeof errors === "object") {
            let html = '<ul class="errors">';

            $.each(errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            errors = html;
          }

          self.$message("error", null, errors);
        });
    },
  },
  mounted() {
    $("#modalTemplate").modal("show");

    $("#modalTemplate").on("show.bs.modal", function () {
      $("div[data-parent='#accordion']").removeClass("show");

      $("#modalTemplate").modal("show");
    });
  },
};
</script>

<style>
</style>