<template>
  <div>
    <div
      class="modal fade"
      id="jobRecruitmentCandidateDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vizualizarPerfilLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-flat-gray-2">
            <h5
              class="modal-title text-dark-blue"
              id="vizualizarPerfilLabel"
            >{{$t('label.perfilDoCandidato')}}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="changeTab('filters2TabContent')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body bg-flat-gray">
            <div class="row">
              <div class="col-md-12">
                <div class="perfil-image float-left mb-3 mr-4">
                  <img
                    :src="((jobCandidate) ? (jobCandidate.candidate) ? jobCandidate.candidate.image : '' : '')"
                    :alt="$t('label.fotoPerfil')"
                  />
                </div>
                <div class="float-left">
                  <h4 class="text-left">{{ candidate.candidate.fullName }}</h4>
                  <p class="text-left mb-0">
                    <span class="small text-left text-dark-blue">{{$t('label.email')}}:</span>
                    {{ candidate.candidate.mail }} /
                    <span
                      class="small text-left text-dark-blue"
                    >{{$t('label.telefone')}}:</span>
                    {{ candidate.candidate.phone }}
                  </p>
                  <h6 class="text-left mt-2">
                    {{$t('label.cargoDesejado')}}:
                    <span
                      class="small text-muted text-left"
                    >{{candidate.candidate.position }}</span>
                  </h6>
                  <h6 class="text-left mt-2">
                    {{$t('label.endereco')}}:
                    <span class="small text-muted text-left">
                      {{ candidate.candidate.address }},
                      {{ candidate.candidate.number }}, {{ candidate.candidate.neighborhood }} - {{ candidate.candidate.cep }}
                    </span>
                  </h6>
                  <h6 class="text-left mt-2">
                    {{$t('label.cidade')}} / {{$t('label.uf')}}:
                    <span
                      class="small text-muted text-left"
                    >
                      {{ candidate.candidate.city }} /
                      {{ candidate.candidate.state }}
                    </span>
                  </h6>
                  <!--<h6 class="text-left mt-2">Pretenção Salarial: <span class="small text-muted text-left" >R$ 4.000,00</span></h6>-->
                  <h6 class="text-left mt-2">
                    {{$t('label.pcd')}}:
                    <span
                      class="small text-muted text-left"
                    >{{candidate.candidate.special_condition == 1 ? $t('label.sim') : $t('label.nao')}}</span>
                  </h6>
                  <h6 class="text-left mt-2">
                    Última atualização do currículo:
                    <span
                      class="small text-muted text-left"
                    >{{candidate.candidate.updated_at}}</span>
                  </h6>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12">
                <h4 class="text-center">Avaliação Inteligência Artificial</h4>
                <b>Pontuação:</b> {{candidate.candidate.score}}
                <br>
                <b>Motivo:</b> {{candidate.candidate.ia_description}}
              </div>
            </div>
            <hr>
            <ul
              class="nav nav-pills nav-justified filters mb-3"
              id="pills-tab-perfil"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-curriculo-tab"
                  data-toggle="pill"
                  href="#curriculo"
                  role="tab"
                  aria-controls="pills-curriculo"
                  aria-selected="true"
                  @click="changeTab('curriculo')"
                >
                  <i class="fa fa-file-text-o"></i>
                  {{$t('label.curriculo')}}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-comentarios-tab"
                  data-toggle="pill"
                  href="#comentarios"
                  role="tab"
                  aria-controls="pills-comentarios"
                  aria-selected="true"
                  @click="changeTab('comentarios')"
                >
                  <i class="fa fa-file-text-o"></i>
                  {{$t('label.resumo')}}
                  <span
                    class="badge bg-danger text-light-gray"
                  >!</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-teste-tab"
                  data-toggle="pill"
                  href="#teste"
                  role="tab"
                  aria-controls="pills-teste"
                  aria-selected="true"
                  @click="changeTab('teste')"
                >
                  <i class="fa fa-list-alt"></i>
                  {{$t('label.teste')}}
                </a>
              </li>
            </ul>

            <div class="tab-content" id="modalTabContent">
              <div
                class="tab-pane fade show active"
                id="curriculo"
                role="tabpanel"
                aria-labelledby="pills-curriculo-tab"
              >
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="text-justify mb-2">{{$t('label.resumo')}}</h5>
                    <p class="text-justify mb-1">{{ candidate.candidate.summary }}</p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-12">
                    <div id="accordion" v-if="candidate.candidate.fullName != ''">
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target-id="#collapseExperiencia"
                            aria-expanded="false"
                            aria-controls="collapseExperiencia"
                          >{{$t('label.experienciaProfissional')}}</button>
                        </div>
                        <div
                          id="collapseExperiencia"
                          class="collapse show"
                          aria-labelledby="headingExperiencia"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="experience in candidate.candidate.experiences"
                              :key="experience.id"
                            >
                              <h5
                                class="text-left mb-1"
                              >{{ experience.position }} - {{ experience.company }}</h5>
                              <p class="small text-muted text-left mb-2">
                                <span>{{$t('label.de')}} {{ format(experience.initial_date) }} {{$t('label.a')}} {{ format(experience.end_date) }}</span>
                              </p>
                              <p class="text-justify mb-0">{{ experience.description }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseEducacao"
                            aria-expanded="false"
                            aria-controls="collapseEducacao"
                          >{{$t('label.educacao')}}</button>
                        </div>
                        <div
                          id="collapseEducacao"
                          class="collapse"
                          aria-labelledby="headingEducacao"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="graduation in candidate.candidate.graduations"
                              :key="graduation.id"
                            >
                              <h5 class="text-left mb-1">{{ graduation.institution }}</h5>
                              <p class="small text-muted text-left mb-2">
                                <span>{{$t('label.de')}} {{ formatPeriod(graduation.period) }}</span>
                              </p>
                              <p
                                class="text-justify mb-0"
                              >{{$t('label.graduacaoEm')}} {{ graduation.course }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseIdiomas"
                            aria-expanded="false"
                            aria-controls="collapseIdiomas"
                          >{{$t('label.idiomas')}}</button>
                        </div>
                        <div
                          id="collapseIdiomas"
                          class="collapse"
                          aria-labelledby="headingIdiomas"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="language in candidate.candidate.languages"
                              :key="language.id"
                            >
                              <h5 class="text-left mb-0">{{ language.description }}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseCursos"
                            aria-expanded="false"
                            aria-controls="collapseCursos"
                          >{{$t('label.cursos')}}</button>
                        </div>
                        <div
                          id="collapseCursos"
                          class="collapse"
                          aria-labelledby="headingCursos"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="course in candidate.candidate.courses"
                              :key="course.id"
                            >
                              <h5 class="float-left mb-0">{{ course.description }}</h5>
                              <p class="small text-muted float-right mb-0">
                                <span>{{ course.institution }}</span>
                              </p>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseCurriculo"
                            aria-expanded="false"
                            aria-controls="collapseCurriculo"
                          >{{$t('label.curriculoAnexo')}}</button>
                        </div>
                        <div
                          id="collapseCurriculo"
                          class="collapse"
                          aria-labelledby="headingCurriculo"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div class="parts-divider">
                              <h5 class="float-left mb-0">
                                <a :href="candidate.candidate.curriculum_doc">
                                  Download
                                  <i class="fa fa-download"></i>
                                </a>
                              </h5>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseConhecimentos"
                            aria-expanded="false"
                            aria-controls="collapseConhecimentos"
                          >{{$t('label.conhecimentos')}}</button>
                        </div>
                        <div
                          id="collapseConhecimentos"
                          class="collapse"
                          aria-labelledby="headingConhecimentos"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="skill in candidate.candidate.skills"
                              :key="skill.id"
                            >
                              <h5 class="float-left mb-0">{{ skill.description }}</h5>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card" v-if="candidate.candidate.networks.length > 0">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target-id="#collapseRedesSociais"
                            aria-expanded="false"
                            aria-controls="collapseRedesSociais"
                          >{{$t('label.redesSociais')}}</button>
                        </div>
                        <div
                          id="collapseRedesSociais"
                          class="collapse"
                          aria-labelledby="headingRedesSociais"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <div
                              class="parts-divider"
                              v-for="network in candidate.candidate.networks"
                              :key="network.id"
                            >
                              <h5 class="float-left mb-0">
                                {{ network.name }} -
                                <a
                                  :href="network.link"
                                  target="_brank"
                                >{{ network.link }}</a>
                              </h5>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade show"
              id="comentarios"
              role="tabpanel"
              v-show="tab == 'comentarios'"
              aria-labelledby="pills-comentarios-tab"
            >
              <h5 class="text-justify mb-2">Outras candidaturas</h5>

              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t('label.nomeDaVaga') }}</th>
                      <th scope="col">Parou na etapa:</th>
                      <th scope="col">Se candidatou em:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="stage in candidate.stages" :key="stage.id">
                      <th
                        scope="row"
                      >{{stage.stage.job ? stage.stage.job.name : 'Não Identificado'}}</th>
                      <td>{{stage.stage.name ? stage.stage.name : 'Não Identificado'}}</td>
                      <td>{{stage.created_at}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
              <div
                class="tab-pane fade show"
                id="teste"
                role="tabpanel"
                v-show="tab == 'teste'"
                aria-labelledby="pills-comentarios-tab"
              >
                <div
                  v-if="questions.length == 0"
                  class="alert alert-warning text-center"
                >{{ $t('label.candidatoAindaNaoRespondeuTeste') }}</div>
                <div class="row" v-if="questions.length > 0">
                  <div class="col-md-12">
                    <div id="accordionQuestions" class="accordion">
                      <div class="card" v-for="(question, index) in questions" :key="index">
                        <div class="card-header">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            :data-target-id="'#collapse' + index"
                            aria-expanded="false"
                            :aria-controls="'collapse' + index"
                          >{{ question.name }}</button>
                        </div>
                        <div
                          :id="'collapse' + index"
                          class="collapse"
                          :aria-labelledby="'heading' + index"
                          data-parent="#accordionQuestions"
                        >
                          <div class="card-body">
                            <div class="row mb-3">
                              <div class="col-md-12">
                                <h6 class="float-left">
                                  {{ $t('label.recebidoEm') }}:
                                  <span
                                    class="small text-muted text-left"
                                  >{{ new Date(question.created_at).toLocaleDateString() + ' - ' + new Date(question.created_at).toLocaleTimeString() }}</span>
                                </h6>
                                <h6 class="float-right">
                                  {{ $t('label.finalizadoEm') }}:
                                  <span
                                    class="small text-muted text-left"
                                  >{{ new Date(question.finalized_date).toLocaleDateString() + ' - ' + new Date(question.finalized_date).toLocaleTimeString() }}</span>
                                </h6>
                              </div>
                            </div>

                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">{{ $t('label.pergunta') }}</th>
                                  <th scope="col">{{ $t('label.resposta') }}</th>
                                  <th scope="col" class="text-center">{{ $t('label.status') }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(answer, index_a) in question.answers" :key="index_a">
                                  <th scope="row">{{index_a + 1}})</th>
                                  <td>{{ answer.question }}</td>
                                  <td>{{ answer.answer }}</td>
                                  <td>
                                    <p class="badge-field text-center">
                                      <span
                                        class="badge bg-green text-light-gray"
                                        v-if="answer.correct == 1"
                                      >{{ $t('label.correta') }}</span>
                                    </p>
                                    <p class="badge-field text-center">
                                      <span
                                        class="badge bg-red text-light-gray"
                                        v-if="answer.correct == 0"
                                      >{{ $t('label.incorreta') }}</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="modal-footer bg-flat-gray-2">
            <button type="button" class="btn delete-btn" data-dismiss="modal">{{$t('label.fechar')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["candidate", "iterations", "stages"],
  data: () => {
    return {
      tab: "",
      observations: [],
      tests:[],
      msg_header: "",
      msg_text: "",
      people: 0,
      tableName: "messageTable",
      url: "/jobCandidateStageIteration",
      columns: ["subject", "content", "created_at"],
      options: {
        debounce: 1000,
        headings: {
          subject: "Assunto",
          content: "Mensagem",
          created_at: "Enviado em"
        }
      }
    };
  },
  computed: {
    jobCandidate: function() {
      return this.candidate;
    },
    iterationsUrl() {
      return this.$store.getters.apiUrl + this.url;
    },
    questions: function() {
      let answers = this.tests.answers;

      let questionnaire_id = "";
      let questionnaire = [];
      for (let index in answers) {
        if (questionnaire_id != answers[index].question.questionnaire.id) {
          questionnaire_id = answers[index].question.questionnaire.id;

          let passport = this.tests.passports.filter(
            passport => passport.questionnaire_id == questionnaire_id
          )[0];

          questionnaire.push({
              name: answers[index].question.questionnaire.name,
              finalized_date: answers[index].created_at,
              created_at: passport.created_at,
              answers: []
            });
          }

          questionnaire[questionnaire.length - 1].answers.push({
              question: answers[index].question.description,
              answer: answers[index].answer.description,
              correct: answers[index].correct
          });
      }
      return questionnaire;
    }
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
      $(".nav-item .nav-link").each(function() {
        $(this).removeClass("active show");
      });
      $(".tab-content .tab-pane").each(function() {
        $(this).removeClass("active show");
      });

      $("#pills-" + tab + "-tab").addClass("active show");
      $("#" + tab).addClass("active show");

      $("#pills-" + "aplicados" + "-tab").addClass("active show");
      $("#" + "aplicados").addClass("active show");
    },
    sendMessage: function() {
      this.$emit(
        "sendMessage",
        this.msg_header,
        this.msg_text,
        this.candidate.id
      );
      this.msg_header = "";
      this.msg_text = "";

      this.$refs.table.refresh();
    },
    format(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatPeriod(date) {
      if (date.length == 39) {
        let firstDate = this.format(date.substring(0, date.length / 2));
        let secondDate = this.format(date.substring(date.length / 2 + 1));

        return firstDate + " - " + secondDate;
      }
      return this.format(date);
    },
      getQuestions(){
      const self = this;
      let api = self.$store.state.apiUrl + '/jobCandidateStage?job_id=' + self.$route.params.id;

      self.$http.get(api,{
        params:{
          with:[
            'candidate.candidate',
            'candidate.answers',
            'candidate.answers.question',
            'candidate.answers.question.questionnaire',
            'candidate.answers.answer',
            'candidate.passports'
          ]
        }
      })
        .then(response => {
          self.tests = !response.data.data[0] == "" ? response.data.data[0].candidate : "";
        })
        .catch(error => {
          self.$errorMessage(error);
        });
    }
  },
  mounted() {
    $("#jobRecruitmentCandidateDetail").on("show.bs.modal", function() {
      $("div[data-parent='#accordion']").removeClass("show");
    });
    this.getQuestions();
  }
};
</script>