<template>
    <div>
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">        
                    <h3 class="card-title">{{ $t('label.edicaoCadastroEmpresas') }}</h3>
                    <form @submit.prevent>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="logo" class="control-label mb-1">{{ $t('label.logo') }}*</label>
                                    <div class="logo-image mb-3"><img :src="companies.photo" :alt="$t('label.logo')" class="logo-companies"></div>
                                    <input type="file" @change="readImage" name="photo" id="photo" class="form-control-file mb-2">                                    
                                    <span class="help-block small text-muted">{{ $t('label.aImagemDeveTer') }} <u>300px X 300px</u> {{ $t('label.eSerEm') }} <u>.png</u></span>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="nome" class="control-label mb-1">{{ $t('label.nome') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-building"></i></div>                                                
                                                <input type="text" class="form-control" v-model="companies.name" maxlength="191" autofocus >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="sobre" class="control-label mb-1">{{ $t('label.sobreAEmpresa') }}*</label>
                                            <div class="input-group">
                                                <div class="input-group-addon"><i class="fa fa-file-text-o"></i></div>                                                
                                                <textarea class="form-control" v-model="companies.about" rows="5" placeholder=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <h3 class="card-title mt-3">{{ $t('label.informacoesEmpresa') }}</h3>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="cep" class="control-label mb-1">{{ $t('label.cep') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input type="text"  v-mask="'#####-###'" class="form-control" id="cep" maxlength="9" name="cep" v-model="companies.cep" @blur="searchCep()">                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="endereco" class="control-label mb-1">{{ $t('label.endereco') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input type="text" class="form-control" v-model="companies.address" maxlength="191">
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="complemento" class="control-label mb-1">{{ $t('label.complemento') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                        <input type="text" placeholder="" class="form-control" v-model="companies.complement">
                                    </div>
                                </div>
                            </div>                                                     
                        </div>
                        <div class="row">                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="bairro" class="control-label mb-1">{{ $t('label.bairro') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input type="text" class="form-control" v-model="companies.neighborhood" maxlength="191">
                                    </div>
                                </div>
                            </div>   
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="numero" class="control-label mb-1">{{ $t('label.numero') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input type="text" class="form-control" v-model="companies.number" maxlength="191">
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="cidade" class="control-label mb-1">{{ $t('label.cidade') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                        <input type="text" class="form-control" v-model="companies.city" maxlength="191">
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="estado" class="control-label mb-1">{{ $t('label.estado') }}*</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>
                                        <input type="text" class="form-control" v-model="companies.state" maxlength="191">
                                    </div>
                                </div>
                            </div>                                                                                
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="erp_code" class="control-label mb-1">{{ $t('label.codigoERP') }}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="fa fa-list-alt"></i></div>
                                        <input type="text" class="form-control" v-model="companies.erp_code" maxlength="191">
                                    </div>
                                </div>
                            </div>                                                                                
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="catho_integration" class="control-label mb-1">{{ $t('label.catho_integration') }}*</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                    <select type="text" class="form-control" v-model="companies.catho_integration" >
                                        <option value="1" >Ativo</option>
                                        <option value="2" >Inativo</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="catho_key" class="control-label mb-1">{{ $t('label.catho_key') }}*</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                    <input type="text" class="form-control" v-model="companies.catho_key" maxlength="191">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="catho_secret" class="control-label mb-1">{{ $t('label.catho_secret') }}*</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="fa fa-map-marker"></i></div>                                        
                                    <input type="text" class="form-control" v-model="companies.catho_secret" maxlength="191">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <router-link class="btn include-btn p-1 ml-3 float-right" to="/companies"><i class="fa fa-reply"></i> {{ $t('label.voltar') }}</router-link>
                                <button @submit.prevent @click="save" id="save-btn" class="btn include-btn p-1 ml-3 float-right"><i class="fa fa-floppy-o"></i> {{ $t('label.salvar') }}</button>
                            </div>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            companies: {
                id: 0,
                name: '',
                about: '',
                photo: '',
                address: '',
                state: '',
                city: '',
                neighborhood: '',
                complement: '',
                number: '',
                cep: '',
                erp_code: '',
            },
            errorCep: false,
            endPoint: "/companies/"
        }
    },
    computed: {
        url() {
            return this.$store.getters.apiUrl + this.endPoint + this.$route.params.id;
        },
        urlUploadImage() {
            return this.$store.getters.apiUrl + this.endPoint + 'image/update/'+ this.$route.params.id;
        }
    },
    mounted: function () {
        this.loadCompanies();
    },
    methods: {
        readImage(ev) {
            const self = this;
            const file = ev.target.files[0];
            const reader = new FileReader();

            reader.onload = e => self.companies.photo = e.target.result;
            reader.readAsDataURL(file);
        },
        loadCompanies() {
            const self = this;
            self.$store.dispatch("tooggleLoadModal");
            this.$http.get(this.url)
                .then((response) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.companies = response.data;
                })
                .catch( () => {
                    self.$store.dispatch("tooggleLoadModal");
                })
        },
        save() {
            const self = this;

            if (this.errorCep) {
                self.$message('error',  self.$t('label.atencao'), self.$t('label.cepInvalido'));
                return;
            }

            let form = {};
            let photo = document.getElementById('photo');

            form['name'] = self.companies.name;
            form['about'] = self.companies.about;
            form['address'] = self.companies.address;
            form['state'] = self.companies.state;
            form['city'] = self.companies.city;
            form['neighborhood'] = self.companies.neighborhood;
            form['complement'] = self.companies.complement;
            form['number'] = self.companies.number;
            form['cep'] = self.companies.cep;
            form['erp_code'] = self.companies.erp_code;
            form['catho_integration'] = self.companies.catho_integration;
            form['catho_key'] = self.companies.catho_key;
            form['catho_secret'] = self.companies.catho_secret;

            if (photo.files[0]) {
                if(photo.files[0].size > 2 * 1024 * 1024){
                    self.$message('error',  self.$t('label.ocorreuUmErro'), self.$t('label.tamanhoFotoEmpresa2mb')); //if image is bigger than 2MB
                        return;
                }
                self.$store.dispatch("tooggleLoadModal");                
                self.updateImage()
                    .then(() => {
                        self.$http.put(self.url, form).then(() => {
                            self.$store.dispatch("tooggleLoadModal");                
                            self.loadCompanies();
                            self.$message('success', self.$t('label.sucesso'), self.$t('label.registroAtualizadoComSucesso'));
                            self.$router.push({
                                path: "/companies/"
                            });
                        }).catch((error) => {
                            self.$store.dispatch("tooggleLoadModal");                
                            self.$errorMessage(error)
                        })
                    })
                    .catch(error => {
                        self.$store.dispatch("tooggleLoadModal");
                        self.$errorMessage(error)
                    })
            }
            else {
                self.$store.dispatch("tooggleLoadModal");
                self.$http.put(self.url, form).then(() => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.loadCompanies();
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.registroAtualizadoComSucesso'));
                    self.$router.push({
                        path: "/companies/"
                    });
                }).catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error)
                })
            }
        },
        updateImage: function () {

            const self = this;

            return new Promise((resolve, reject) => {
                
                const api = self.urlUploadImage;
                let form = new FormData();
                form.append('photo', document.getElementById('photo').files[0]);

                this.$http.post(api, form)
                    .then(() => {
                        self.$message('success', self.$t('label.sucesso'), self.$t('label.imagemEmpresaAtualizadaComSucesso'));
                        resolve();
                    })
                    .catch((error) => {
                        reject(error)
                    });

            })
            
        },
        searchCep() {
            let self = this;
            const cep = this.companies.cep.toString().replace(/\D/g, '');

            if (cep != "") {

                const validacep = /^[0-9]{8}$/;

                if (validacep.test(cep)) {

                    this.companies.address = "...";
                    this.companies.city = "...";
                    this.companies.neighborhood = "...";
                    this.companies.state = "...";

                    //Consulta o webservice viacep.com.br/
                    this.$.getJSON("//viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {
                        if (dados.erro) {
                            self.errorCep = true
                        }else {
                            self.errorCep = false
                        }

                        if (!("erro" in dados)) {
                            self.companies.address = dados.logradouro;
                            self.companies.city = dados.localidade;
                            self.companies.neighborhood = dados.bairro;
                            self.companies.state = dados.uf;
                        }
                    })
                }
            }
        }
    }
}
</script>

<style>

</style>