var messages =
// 20200123150256
{
  ptBR: {
    route: {
      dashboard: "Dashboard",
      vagas: "Vagas",
      vagasInternas: "Vagas Internas",
      aprovacoes: "Aprovações",
      requisitarVaga: "Requisitar Vaga",
      selecao: "Seleção",
      questionario: "Questionários",
      documentacao: "Documentação",
      geral: "Geral",
      empresas: "Empresas",
      estabelecimentos: "Estabelecimentos",
      centroDeCusto: "Centro de custo",
      recrutadores: "Recrutadores",
      gestoresDeArea: "Gestores de Área",
      cargos: "Cargos",
      templateDeCargos: "Templates de Cargo",
      conheca: "Conheça",
      questionarios: "Questionários",
      adicionarQuestionario: "Adicionar Questionário",
      editarQuestionario: "Editar Questionário",
      novoTemplate: "Novo Template",
      templates: "Templates",
      editarTemplate: "Editar Template",
      novoCargo: "Novo Cargo",
      editarCargo: "Editar Cargo",
      consultarCargo: "Consultar Cargo",
      gerenciamentoGestoresArea: "Gerenciamento de Gestores de Área",
      gerenciamentoRecrutadores: "Gerenciamento de Recrutadores",
      centroCusto: "Centro de custo",
      novosCentrosCusto: "Novos centros de custo",
      editarCentroCusto: "Editar centros de custo",
      consultarCentrosCusto: "Consultar centros de custo",
      consultarEstabelecimento: "Consultar Estabelecimento",
      editarEstabelecimento: "Editar Estabelecimento",
      novoEstabelecimento: "Novo Estabelecimento",
      consultarEmpresa: "Consultar Empresa",
      editarEmpresa: "Editar Empresa",
      novaEmpresa: "Nova Empresa",
      edicaoCadastroEmpresas: "Edição de Cadastro de Empresas",
      consultarTemplate: "Consultar Template",
      vagasEmAprovacao: "Vagas em aprovação",
      consultarQuestionario: "Consultar Questionário",
      aprovarVaga: "Aprovar Vaga",
      publicarVaga: "Publicar vaga",
      instalacao: "Instalação",
      ativacao: "Ativação",
      aSuaLicencaConfiguradaComBaseNaSuaPropostaComercial: "A sua licença é configurada com base na sua proposta comercial.",
      visualizarVaga: "Visualizar Vaga",
      jobDescription: "Revisar",
      consultarEtapas: "Consultar Etapas",
      triagem: "Triagem",
      login: "Login",
      usuarios: "Usuários",
      novosUsuarios: "Novo Usuário",
      editarUsuario: "Editar Usuário",
      consultarUsuario: "Consultar Usuário",
      novaLicenca: "Nova licença",
      editarLicenca: "Editar licença",
      consultarLicenca: "Consultar licença",
      reprovacoes: "Reprovações",
      vagasReprovadas: "Vagas Não Aprovadas",
      integracoes: "Integrações",
      integracoesConfig: "Configurações das Integrações",
      integracoesManager: "Gerenciamento da Integração",
      novaIntegracao: "Nova Integracao",
      editarIntegracao: "Editar Integração",
      gestaoDaVaga: "Gestão da vaga",
      portal: "Portal",
      email_templates: "Templates de Email",
      email_templatesAdd: "Novo Template de Email",
      minhasVagas: "Minhas Vagas",
      minhasVagasGerenciadas: "Vagas Gerenciadas",
      talent_bank: "Banco de Talentos",
      cv_bank: "Banco de Currículos",
      meuPerfil: "Meu Perfil",
      workload: "Horários de Trabalho",
      licencas: "Licenças"
    },
    label: {
      idiomaPortugues: "Português",
      idiomaIngles: "Inglês",
      idiomaEspanhol: "Espanhol",
      vagas: "Vagas",
      aprovacao: "Aprovação",
      jobDescription: "Alinhamento da vaga",
      publicacao: "Publicação",
      triagem: "Triagem",
      selecao: "Seleção",
      concluidas: "Concluídas",
      contratacoes: "Contratações",
      ultimasContratacoes: "Últimas contratações",
      pesquisarAqui: "Pesquisar aqui",
      vagasSemRecrutador: "Vagas Sem Recrutador",
      selecione: "Selecione",
      titulo: "Título",
      candidatos: "Candidatos",
      prazo: "Prazo",
      recrutador: "Recrutador",
      mostrando: "Mostrando",
      registros: "Registros",
      deRegistros: "De registros",
      semRecrutador: "Sem Recrutador",
      assumir: "Assumir",
      definirRecrutador: "Definir Recrutador",
      desejaResponsavelVaga: "Tem certeza que deseja ser o responsável pela vaga?",
      nao: "Não",
      sim: "Sim",
      todosDireitos: "Todos os direitos reservados",
      desenvolvidoPor: "Desenvolvido por",
      fechar: "Fechar",
      ate: "Até",
      filtros: "Filtros",
      de: "De",
      filtroDataNaoInformadoCorretamente: "Filtro de data não informado corretamente",
      copyright: "Copyright",
      nenhumRegistroEncontrado: "Nenhum registro encontrado ",
      pagina: "Página",
      vagasCadastradas: "Vagas Cadastradas",
      procurarVaga: "Procurar Vaga",
      ordenar: "Ordenar",
      ordenarCrescente: "Ordenar Crescente",
      ordenarDecrescente: "Ordenar Decrescente",
      nomeDaVaga: "Nome da Vaga",
      dataDeConclusao: "Data de Conclusão",
      area: "Área",
      solicitante: "Solicitante",
      dataDeAbertura: "Data de Abertura",
      pesquisePorSolicitante: "Pesquise por solicitante",
      pesquisePorRecrutador: "Pesquise por recrutador",
      pesquisePorArea: "Pesquise por área",
      dataFinalNaoPodeSerMenuDataInicial: "Data Final não pode ser menor que a data Inicial",
      nenhumResultadoComFiltroInformado: "Nenhum Resultado Com o filtro informado",
      todas: "Todas",
      canceladas: "Canceladas",
      definirTransferirRecrutador: "Definir/Transferir Recrutador",
      cancelarVaga: "Cancelar Vaga",
      abertura: "Abertura",
      conclusao: "Conclusão",
      salario: "Salário",
      cancelado: "Cancelado",
      finalizado: "Finalizado",
      acombinar: "A Combinar",
      naoInformado: "Não informado",
      temCertezaCancelarVaga: "Tem certeza que deseja cancelar a vaga?",
      porFavorInformeMotivo: "Por favor, informe o motivo.",
      vagaCanceladaComSucesso: "Vaga cancelada com sucesso!",
      naoFoiPossivelCancelarVaga: "Não foi possível cancelar a vaga!",
      sucesso: "Sucesso",
      ocorreuUmErro: "Ocorreu um erro",
      informacoes: "Informações",
      detalhesDaVaga: "Detalhes da Vaga",
      linkPublico: "Link Público",
      tituloDaVaga: "Título da Vaga",
      vagasDisponiveis: "Vagas Disponíveis",
      NomeDaEmpresa: "Nome da Empresa",
      vaga: "Vaga",
      anunciadaEm: "Anunciada em",
      expiraEm: "Expira em",
      aprovada: "Aprovada",
      reprovada: "Reprovada",
      vagaReprovadaPor: "Vaga reprovada por",
      vagaAprovadaPor: "Vaga aprovada por",
      em: "Em",
      observacao: "Observação",
      descricaoDaVaga: "Descrição da Vaga",
      requisitos: "Competências Técnicas Essenciais",
      competenciasComportamentais: "Competências Comportamentais",
      diferenciais: "Diferenciais",
      beneficios: "Benefícios",
      regime: "Regime",
      sobreAEmpresa: "Sobre a empresa",
      cadastroQuestionarioClassificatorios: "Cadastro de Questionários Classificatórios",
      consulteQuestionarioParaVerQuestoes: "Consulte um questionário para ver as questões",
      cadastrar: "Cadastrar",
      nome: "Nome",
      visualizar: "Visualizar",
      editar: "Editar",
      deletar: "Deletar",
      acoes: "Ações",
      desejaDeletarRegistro: "Deseja deletar o registro?",
      essaOperacaoNaoPodeSerDesfeita: "Essa operação não pode ser desfeita",
      perguntas: "Perguntas",
      voltar: "Voltar",
      salvar: "Salvar",
      registroExcluidoComSucesso: "Registro excluído com sucesso!",
      registroSalvoComSucesso: "Registro salvo com sucesso!",
      pergunta: "Pergunta",
      alternativa: "Alternativa",
      alternativaCorreta: "Alternativa Correta?",
      adicionarAlternativa: "Adicionar alternativa",
      registroAtualizadoComSucesso: "Registro atualizado com sucesso!",
      atencao: "Atenção",
      perguntaPrecisaTerNoMinimo1Alternativa: "A pergunta precisa ter pelo menos 1 alternativa!",
      NãoEPossivelDeletarAAlternativaJaFoiUsadaResposta: "Não é possível deletar! A alternativa já foi usada como resposta.",
      nova: "Nova",
      questionario: "Questionários",
      cadastroDeTemplate: "Cadastro de Template",
      cargo: "Cargo",
      descricao: "Descrição",
      diferencial: "Diferencial",
      complementos: "Complementos",
      tipoDeAnuncio: "Tipo de Anúncio",
      interno: "Interno",
      externo: "Externo",
      ambos: "Ambos",
      regimeContratacao: "Regime de Contratação",
      clt: "Efetivo",
      pj: "PJ",
      estagiario: "Estagiário",
      temporario: "Temporário",
      freelancer: "Free-Lancer",
      trainee: "Trainee",
      apprentice: "Aprendiz",
      beneficio: "Benefício",
      incluirBeneficios: "Incluir Benefícios",
      naoFoiPossivelBuscarInformacoesReferencesAEmpresa: "Não foi possível buscar as informações referente ao Empresa",
      naoFoiPossivelBuscarInformacoesReferencesAoCliente: "Não foi possível buscar as informações referente ao Cliente",
      cadastroTemplates: "Cadastro de Templates",
      consultarEtapas: "Consultar Etapas",
      cadastroDeCargos: "Cadastro de Cargos",
      gerenciamentoDeUsuarios: "Gerenciamento de Usuários",
      selecioneUsuarios: "Selecione os usuários",
      gestores: "Gestores",
      pesquisar: "Pesquisar",
      pesquisarUsuariosPlataforma: "Pesquisar usuários na plataforma",
      suaLicencaEstaLimitada: "Sua licença está limitada a",
      gestorDeArea: "Gestor{s} de área.",
      usuariosDisponiveis: "Usuários Disponíveis",
      matricula: "Matrícula",
      email: "E-mail",
      usuariosCadastrados: "Usuários Cadastrados",
      selecionar: "Selecionar",
      trocar: "Trocar",
      cancelarTroca: "Cancelar Troca",
      jaAdicionado: "Já adicionado!",
      usuarioAdicionadoComSucesso: "Usuário adicionado com sucesso!",
      naoFoiPossivelAdicionarUsuarioSelecionado: "Não foi possível adicionar o usuário selecionado",
      eNecessarioAoMenosUmUsuario: "É necessário ao menos 1 usuário",
      usuarioRemovidoComSucesso: "Usuário removido com sucesso!",
      naoFoiPossivelRemoverUsuarioSelecionado: "Não foi possível remover o usuário selecionado.",
      gerenciamentoRecrutadores: "Gerenciamento de Recrutadores",
      recrutadores: "Recrutadores",
      recrutadorS: "Recrutador{s}",
      recrutadorAdicionadoComSucesso: "Recrutador adicionado com sucesso!",
      naoFoiPossivelAdicionarRecrutadorSelecionado: "Não foi possível adicionar o recrutador selecionado.",
      naoFoiPossivelRemoverRecrutadorSelecionado: "Não foi possível remover o recrutador selecionado.",
      recrutadorRemovidoComSucesso: "Recrutador removido com sucesso!",
      selecioneUmOutroRecrutadorParaTrocarComORecrutadorASerRemovido: "Selecione um outro recrutador para trocar com o recrutador a ser removido.",
      existemVagasVinculadasAEsseRecrutador: "Existem vagas vinculadas a esse recrutador!",
      usuariosRecrutadores: "Usuários recrutadores",
      desejaRealizarATrocaDeAlgumUsuario: "Deseja realizar a troca de algum usuário?",
      cadastroCentroCusto: "Cadastro de Centro de custo",
      centroCusto: "Centro de Custo",
      departamento: "Departamento",
      aprovador: "Aprovador",
      visualizacaoCadastroCentroCusto: "Visualização de Cadastro de Centro de custo",
      cadastroEstabelecimento: "Cadastro de Estabelecimentos",
      visualizacaoCadastroEstabelecimento: "Visualização de Cadastro de Estabelecimentos",
      edicaoCadastroEstabelecimento: "Edição de Cadastro de Estabelecimentos",
      cidade: "Cidade",
      estado: "Estado",
      cadastroEmpresas: "Cadastro de Empresas",
      mensagemCadastroEmpresa: "O cadastro de empresa tem informações referentes ao HumanFlow, e não condizem com o cadastro de empresa na plataforma",
      logotipo: "Logo",
      aImagemDeveTer: "A imagem deve ter",
      eSerEm: "E ser em",
      informacoesEmpresa: "Informações da Empresa",
      informacoesEstabelecimento: "Informações do Estabelecimento",
      cep: "CEP",
      endereco: "Endereço",
      numero: "Número",
      complemento: "Complemento",
      bairro: "Bairro",
      tamanhoFotoEmpresa2mb: "O tamanho da Foto da empresa precisa ser de no máximo 2MB",
      visualizacaoCadastroEmpresas: "Visualização de Cadastro de Empresas",
      imagemEmpresaAtualizadaComSucesso: "Imagem da empresa atualizada com sucesso!",
      semPermissaoEditarArquivo: "Sem permissão para editar arquivos.",
      arrasteSolteOrderDesejadaDepoisCliqueBotao: "Arraste e solte na ordem desejada, depois clique no botão",
      salvarOrdenacao: "Salvar Ordenação",
      adicionarCampo: "Adicionar Campo",
      naoFoiPossivelCarregarAsEtapas: "Não foi possível carregar as Etapas",
      configuracaoEtapa: "Configuração de Etapa",
      tipo: "Tipo",
      comum: "Comum",
      teste: "Teste",
      entrevista: "Entrevista",
      etapas: "Etapas",
      template: "Template",
      requisitarVaga: "Requisitar Vaga",
      empresa: "Empresa",
      estabelecimentos: "Estabelecimento",
      templateCargo: "Template do Cargo",
      numeroVagas: "Número de Vagas",
      tipoVaga: "Tipo da Vaga",
      reposicao: "Reposição",
      informacoesCargo: "Informações do Cargo",
      observacoes: "Observações",
      digiteAqui: "Digite aqui",
      exibirSalario: "Exibir Salário",
      cancelar: "Cancelar",
      iniciar: "Iniciar",
      centroCustoObrigatorio: "Centro de Custo é obrigatório",
      estabelecimentoObrigatorio: "Estabelecimento é obrigatório",
      empresaObrigatoria: "Empresa é obrigatório",
      tituloObrigatoria: "Campo Título da vaga é obrigatório",
      requirementsObrigatoria: "Campo Requisitos é obrigatório",
      differentialObrigatoria: "Campo Diferencias é obrigatório",
      formationObrigatoria: "Campo Formação é obrigatório",
      workplaceObrigatoria: "Campo Local de Trabalho é obrigatório",
      vagasPendentesAprovacao: "Vagas Pendentes de Aprovação",
      vagasInternas: "Vagas Internas",
      edicaoCadastroEmpresas: "Editar Empresa",
      AprovacaoDeVaga: "Aprovação de Vaga",
      voceNaoEAprovadorDessaVaga: "Você não é o aprovador desta vaga",
      selecioneEmpresa: "Selecione a Empresa",
      selecioneEstabelecimento: "Selecione o Estabelecimento",
      selecioneCentroCusto: "Selecione o Centro de Custo",
      estabelecimento: "Estabelecimento",
      valor: "Valor",
      Aprovar: "Aprovar",
      Reprovar: "Reprovar",
      vagaSemRecrutador: "Vaga sem recrutador!",
      voceNaoERecrutadorDessaVaga: "Você não é o recrutador dessa vaga",
      erro: "Erro",
      segmentosDeAtuacao: "Segmentos de Atuação",
      nenhumCandidatoEstagioFinal: "Nenhum candidato esta no estágio final",
      perfilDoCandidato: "Perfil do Candidato",
      informacoesDeMercado: "Informações de Mercado",
      priorizaCandidadosInternos: "Prioriza Candidatos Internos",
      observacoesAdicionais: "Observações Adicionais",
      finalizar: "Finalizar",
      erroCarregarPlataformas: "Erro ao carregar plataformas",
      vagaPostadaComSucesso: "Vaga postada com Sucesso",
      publicarVaga: "Publicar Vaga",
      publicado: "Publicado",
      definir: "Definir",
      emEdicao: "Em Edição",
      emRecrutamento: "Em Recrutamento",
      aguardandoPublicacao: "Aguardando Publicação",
      emAprovacao: "Em aprovação",
      umaVagaAPreencher: "Uma vaga a preencher",
      vagasAPreencher: "Vagas a preencher",
      candidatosAprovados: "Candidatos Aprovados",
      telefone: "Telefone",
      reprovacao: "Reprovação",
      vagaCanceladaPor: "Vaga cancelada por ",
      buscamosPessoasCom: "Buscamos pessoas com",
      segmento: "Segmento",
      quantidade: "Quantidade",
      informacoesAdicionais: "Observações Adicionais",
      perfil: "Perfil",
      voceTem: "Você tem",
      vagasEmAprovacao: "Vagas em aprovação",
      vagaCancelada: "Vaga Cancelada",
      vagaConcluida: "Vaga Concluída",
      observacaoAprovador: "Observação do aprovador",
      marcarTodosCandidatos: "Marcar Todos Candidatos",
      moverPara: "Mover Para",
      aplicados: "Aplicados",
      selecionados: "Selecionados",
      desclassificados: "Desclassificados",
      mensagem: "Mensagem",
      selecioneAoMenosUmRegistro: "Selecione ao menos um registro",
      primeiraPagina: "Primeira página",
      ultimaPagina: "Última página",
      alerta: "Alerta",
      selecioneAoMenosUmCandidatoParaProsseguir: "Selecione ao menos um candidato antes de prosseguir!",
      ocorreuUmErroAoCarregarInformacoesVaga: "Ocorreu um erro ao carregar as informações da vaga.",
      atualizadoComSucesso: "Atualizado com Sucesso",
      candidatosAtualizadosComSucesso: "Candidatos Atualizados com Sucesso!",
      ocorreuUmErroCarregarInformacoesCandidatos: "Ocorreu um erro ao carregar as informações dos Candidatos",
      ocorreuUmErroCarregarInformacoesCandidatosDesclassificados: "Ocorreu um erro ao carregar as informações dos Candidatos Desclassificados",
      mensagemEnviadaComSucesso: "Mensagem enviada com Sucesso",
      ok: "Ok",
      visualizarPerfil: "Visualizar Perfil",
      anterior: "Anterior",
      proximo: "Próximo",
      naoHaCandidatosDesclassificados: "Não há candidatos desclassificados!",
      naoHaCandidatosSelecionados: "Não há candidatos selecionados!",
      naoHaCandidatosAplicados: "Não há candidatos aplicados!",
      conhecimentos: "Conhecimentos",
      cursos: "Cursos",
      idiomas: "Idiomas",
      graduacaoEm: "Graduação em",
      educacao: "Educação",
      a: "A",
      experienciaProfissional: "Experiência profissional",
      resumo: "Resumo",
      curriculo: "Currículo",
      comentarios: "Comentários",
      uf: "UF",
      pcd: "PCD",
      cargoDesejado: "Cargo Desejado",
      fotoPerfil: "Foto Perfil",
      buscaGeral: "Busca Geral",
      pesquisaEmVariasInformacoesDoPerfilDoCandidatoComoResumoeDescricao: "Pesquisa em várias informações do perfil do candidato, como resumo e descrição",
      cargoPretendido: "Cargo Pretendido",
      escolhaNaListaDeCargosDosCandidatosParaEssaVaga: "Escolha na lista de cargos dos candidatos para essa vaga",
      escolhaNaListaDeCidadesDosCandidatosParaEssaVaga: "Escolha na lista de cidades dos candidatos para essa vaga",
      escolhaNaListaDeEstadosDosCandidatosParaEssaVaga: "Escolha na lista de estados dos candidatos para essa vaga",
      objetivo: "Objetivo",
      escolhaNaListaDeObjetivosDosCandidatosParaEssaVaga: "Escolha na lista de objetivos dos candidatos para essa vaga",
      habilidades: "Habilidades",
      escolhaNaListaDeHabilidadesDosCandidatosParaEssaVaga: "Escolha na lista de habilidades dos candidatos para essa vaga",
      idioma: "Idioma",
      graduacao: "Graduação",
      instituicao: "Instituição",
      cursosLivres: "Cursos Livres",
      escolhaNaListaDeCursosLivresDosCandidatosParaEssaVaga: "Escolha na lista de cursos dos candidatos para essa vaga",
      escolhaNaListaDeInstituicoesDosCandidatosParaEssaVaga: "Escolha na lista de instituições dos candidatos para essa vaga",
      escolhaNaListaDeCursosDosCandidatosParaEssaVaga: "Escolha na lista de cursos dos candidatos para essa vaga",
      escolhaNaListaDeIdiomasDosCandidatosParaEssaVaga: "Escolha na lista de idiomas dos candidatos para essa vaga",
      ativacaoIniciadaEmBreveVoceReceberaoTokenPorEmail: "Ativação iniciada, em breve você receberá o token por e-mail",
      ativacaoIniciadaComSucessoEmBreveVoceReceberaoTokenDeInstalacaoPorEmail: "Ativação iniciada com sucesso. Em breve você receberá o token de instalação por e-mail.",
      bemVindoAoHumanFlow: "Bem-vindo ao HumanFlow!",
      paraIniciaraAtivacaoPreenchaoFormularioAbaixo: "Para iniciar a ativação, preencha o formulário abaixo",
      cnpj: "CNPJ",
      iniciarAtivacao: "Iniciar Ativação",
      aposIniciaraAtivacaoEmBreveVoceReceberaUmEmailComAsSeguintesInformacoes: "Após iniciar a ativação, em breve você receberá um e-mail com as seguintes informações",
      tokenParaInstalacao: "Token para instalação",
      quantidadedeRecrutadores: "Quantidade de Recrutadores",
      quantidadedeGestores: "Quantidade de Gestores",
      essaConfiguracaoeRealizadaComBaseNaSuaPropostaComercial: "Essa configuração é realizada com base na sua proposta comercial.",
      bemVindoAoInstaladorDoHumanFlowVoceReceberaoTokenNoEmailInformadoNoCadastro: "Bem-vindo ao instalador do HumanFlow! Você receberá o token no e-mail informado no cadastro.",
      foiSelecionadoUmNumeroDeUsuariosMaiorQueoContratado: "Foi selecionado um número de usuários maior que o contratado!",
      selecioneAoMenosUmUsuario: "Selecione ao menos 1 usuário",
      foiSelecionadoUmNumeroDeRecrutadoresMaiorQueoContratado: "Foi selecionado um número de recrutadores maior que o contratado!",
      selecioneAoMenosUmRecrutador: "Selecione ao menos um recrutador.",
      favorInformaroTokenHumanFlow: "Favor informar o token HumanFlow",
      tokenInvalido: "Token inválido",
      instalacaoDoHumanFlow: "Instalação do HumanFlow",
      parabens: "Parabéns",
      aInstalacaoFoiConcluidaComCucesso: "A instalação foi concluída com sucesso!",
      cliqueNoBotaoAbaixoParaAcessaroHumanFlow: "Clique no botão abaixo para acessar o HumanFlow.",
      acessarHumanFlow: "Acessar HumanFlow",
      emCasoDeDuvidasEnvieUmEmailPara: "Em caso de dúvidas, envie um e-mail para",
      instalacao: "Instalação",
      sigaoProcessoPassoaPasso: "Siga o processo passo a passo",
      ativacao: "Ativação",
      digiteSeuToken: "Digite seu Token",
      identificacaoDeRecrutadores: "Identificação de Recrutadores",
      aPesquisaeLimitadaEmAte20RegistrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado: "A pesquisa é limitada em até 20 registros, então pode ser necessário refinar a busca para encontrar o usuário desejado.",
      usuariosPesquisados: "Usuários Pesquisados",
      pesquiseOsUsuariosNoCampoAcima: "Pesquise os usuários no campo acima.",
      selecioneOsUsuariosAoLado: "Selecione os usuários ao lado.",
      identificacaoDeGestoresDeArea: "Identificação de Gestores de Área",
      gestoresDeArea: "Gestores de Área",
      saoOsUsuariosQueIraoSolicitarVagasOuAprovarRequisicoes: "São os usuários que irão solicitar vagas ou aprovar requisições",
      aPesquisaeLimitadaEmAte20registrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado: "A pesquisa é limitada em até 20 registros, então pode ser necessário refinar a busca para encontrar o usuário desejado.",
      usuariosGestores: "Usuários Gestores",
      clickEmFinalizarParaConcluiraInstalacao: "Click em finalizar para concluir a instalação",
      instalando: "Instalando",
      cadastrandoUsuarios: "Cadastrando usuários",
      cadastrandoRecrutadores: "Cadastrando recrutadores",
      naoSePreocupeCasoNumeroDeLicencasEstejaDivergentePoisTambemePossivelAtualizarRecrutadoresGestoresAposaInstalacao: "Não se preocupe caso o número de licenças esteja divergente, pois também é possível atualizar os recrutadores e gestores após a instalação.",
      ouContateSeuExecutivoDeContas: "Ou contate seu executivo de contas",
      emCasoDeDivergenciaOuDuvidasEntreEmContatoAtravesDoEmail: "Em caso de divergência ou dúvidas, entre em contato através do e-mail",
      nenhumUsuarioEncontrado: "Nenhum usuário encontrado!",
      usuarioEstaCadastradoComoRecrutador: "Usuário está cadastrado como recrutador",
      usuarioEstaCadastradoComoGestor: "Usuário está cadastrado como gestor",
      removaPrimeirooUsuarioDosRecrutadoresCadastrados: "Remova primeiro o usuário dos recrutadores cadastrados.",
      removaPrimeirooUsuarioDosGestoresCadastrados: "Remova primeiro o usuário dos gestores cadastrados.",
      voceNaoTemPermissaoDeAcesso: "Você não tem permissão de acesso!",
      naoFoiPossivelSalvarOIdioma: "Não foi possível salvar o idioma!",
      cepInvalido: "CEP não é válido! Por favor, preencha corretamente.",
      logo: "Logo",
      redesSociais: "Redes Sociais",
      selecionePeloMenos1CandidatoParaEnviaroTeste: "Selecione pelo menos 1 candidato para enviar o teste!",
      desejaEnviarOsTestesParaOsCandidatos: "Deseja enviar os testes para os candidatos?",
      testeEnviadoComSucessoParaCandidatosSelecionados: "Teste enviado com sucesso para os candidatos selecionados!",
      ocorreuUmErroAoAtualizarAsInformacoesDosCandidatos: "Ocorreu um erro ao atualizar as informações dos candidatos",
      comentarioAdicionadoComSucesso: "Comentário adicionado com sucesso!",
      ocorreuUmErroAoRealizarUmaInteracao: "Ocorreu um erro ao realizar uma interação",
      nenhumComentarioParaExibir: "Nenhum comentário para exibir!",
      aas: "às",
      candidatoAindaNaoRespondeuTeste: "Candidato ainda não respondeu o teste!",
      candidatoAindaNaoRecebeuoTeste: "Candidato ainda não recebeu o teste!",
      recebidoEm: "Recebido em",
      finalizadoEm: "Finalizado em",
      status: "Status",
      resposta: "Resposta",
      incorreta: "Incorreta",
      correta: "Correta",
      finalizarProcessoSeletivo: "Finalizar Processo Seletivo?",
      processoFinalizadoComExito: "Processo finalizado com êxito!",
      processoSeletivo: "Processo Seletivo",
      dataFinal: "Data Final",
      enviarTeste: "Enviar Teste",
      descricaoNaoInformada: "Descrição não informada!",
      nenhumaEtapaCadastradaCadastreEtapas: "Nenhuma etapa cadastrada! Cadastre as etapas.",
      arrasteSolteOrderDesejada: "Arraste e solte na ordem desejada.",
      vagasAbertas: "Vagas abertas",
      aSuaLicencaConfiguradaComBaseNaSuaPropostaComercial: "A sua licença é configurada com base na sua proposta comercial.",
      sair: "Sair",
      desejaSairHumanflow: "Deseja sair do Humanflow?",
      naoFoiPossivelRealizarLogin: "Não foi possível realizar o login!",
      acesseHumanflow: "Acesse o Humanflow",
      senha: "Senha",
      entrar: "Entrar",
      image: "Imagem",
      visualizacaoCadastroUsuario: "Visualizar Usuário",
      cadastroUsuario: "Cadastro de usuário",
      cadastroLicencas: "Cadastro de Licenças",
      normal: "Normal",
      reprovado: "Reprovado",
      vagasReprovadas: "Vagas Não Aprovadas",
      emBreve: "Em Breve",
      publicarNosSites: "Publicar nos sites",
      publiqueAsVagasNosSitesPredefinidos: "Publique as vagas nos sites predefinidos.",
      compartilharNasRedes: "Compartilhar nas redes",
      compartilheAsVagasNasRedesCadastradas: "Compartilhe as vagas nas redes cadastradas.",
      integracoes: "Integrações",
      configureGerencieIntegracoesERP: "Configure e gerencie as integrações com seus ERPs.",
      configurar: "Configurar",
      configuracaoIntegracao: "Configurações das Integrações",
      incluirIntegracoes: "Incluir Integrações",
      gerenciar: "Gerenciar",
      nomeErp: "Nome do ERP",
      linkWebService: "Link do Web Service",
      sincronizarAutomaticamente: "Sincronizar Automaticamente",
      integracaoHabilitada: "Integração Habilitada",
      cadastroIntegracoes: "Cadastro de Integrações",
      gerenciamentoIntegracao: "Gerenciamento da Integração",
      qualODadoASerImportado: "Qual o dado a ser importado?",
      atualizarDados: "Atualizar os dados",
      cliqueParaAtualizarOsDados: "Clique para atualizar os dados",
      habilitarImportacao: "Habilitar importação",
      empresaPrincipal: "Empresa Principal",
      filialPrincipal: "Filial Principal",
      testarConexao: "Testar Conexão",
      catho_integration: "Integração Catho",
      catho_key: "Catho APP TOKEN",
      catho_secret: "Catho TOKEN",
      codigoERP: "Código ERP",
      cadastroIntegracao: "Cadastro de Integração",
      selecioneOErp: "Selecione o ERP",
      informeOLinkWS: "Informe o link do web service",
      webserviceConectadoComSucesso: "WebService conectado com sucesso",
      naoFoiPossivelConectarNoWebService: "Não foi possível conectar no web service",
      exportarDadosParaOHumanflow: "Integrar dados para o Humanflow",
      dadosAtualizadosComSucesso: "Dados atualizados com sucesso!",
      naoFoiPossivelAtualizarOsDados: "Não foi possível atualizar os dados",
      exportarNovosFuncionarios: "Integrar funcionários",
      enviarEmailRequisitarDados: "Enviar e-mail para requisitar dados",
      enviarCandidatoParaOErp: "Enviar candidato para ERP",
      desejaEnviarEmailCandidatosAprovados: "Deseja enviar e-mail para os candidatos aprovados solicitando as informações pendentes para contratação?",
      oCandidatoIraReceberEmailFormulario: "O candidato irá receber um e-mail, onde terá um link para acessar o formulário para informar os dados necessários.",
      emailEnviado: "E-mail enviado",
      emailRespondido: "E-mail respondido",
      candidatoExportado: "Candidato integrado",
      integracaoProtheus: "Integração Protheus",
      enviar: "Enviar",
      selecioneUmaEmpresa: "Selecione uma empresa",
      genero: "Gênero",
      masculino: "Masculino",
      feminino: "Feminino",
      idade: "Idade",
      alterarVisibilidade: "Alterar Visibilidade",
      naoFoiPossivelMudarAVisibilidadeDaVaga: "Não foi possível mudar a visibilidade da vaga",
      gestaoDaVaga: "Gestão da vaga",
      visivelNoJobs: "Visível no Jobs",
      visibilidadeAlteradaComSucesso: "Visibilidade alterada com sucesso",
      numeroDeVagas: "Número de vagas",
      exibirNoPortalJobs: "Exibir vaga no portal Jobs",
      aumentoDeQuadro: "Aumento de Quadro",
      motivo: "Motivo",
      solicitadas: "Solicitadas",
      publicadas: "Publicadas",
      aprovadas: "Aprovadas",
      substituicaoPorDemissao: "Substituição por Desligamento",
      substituicaoPorAlteracao: "Substituição por Alteração",
      substituicaoTemporaria: "Substituição por Afastamento",
      budgeted: "Está previsto no orçamento?",
      competenciasDesejadas: "Competências Técnicas Desejadas",
      informacaoComplementar: "Informação Complementar",
      aprendiz: "Aprendiz",
      terceirizado: "Terceirizado",
      configuraçoesDePublicacao: "Configurações de publicação",
      exibirValor: "Exibir valor",
      aCombinar: "A combinar",
      informacoesDoPortalDeCandidatos: "Informações do portal de candidatos",
      foto1: "Foto 1",
      foto2: "Foto 2",
      foto3: "Foto 3",
      linkVideo: "Link do Vídeo",
      bannerPrincipal: "Banner principal",
      website: "Website",
      textoDasVagas: "Texto das vagas",
      twitter: "Twitter",
      linkedin: "Linkedin",
      instagram: "Instagram",
      youtube: "Youtube",
      facebook: "Facebook",
      horarioTrabalho: "Horário de Trabalho",
      formacao: "Formação Acadêmica",
      vinculoAprovadores: "Aprovadores",
      numeroDaVaga: "Número da Vaga",
      AprovadoresDaVaga: "Aprovadores da vaga",
      cursosAdicionais: "Cursos Adicionais",
      cargaHoraria: "Carga Horária Mensal",
      justificativa: "Justificativa",
      temCertezaReabrirVaga: "Tem certeza que deseja reabrir a vaga?",
      vagaReabertaComSucesso: "Vaga reaberta com sucesso",
      naoFoiPossivelReabrirVaga: "Não foi possível reabrir a vaga",
      localTrabalho: "Local de Trabalho",
      cadastroEmailTemplate: "Cadastro de Templates de Email",
      bodyEmail: "Corpo do e-mail",
      principaisAtribuicoes: "Principais Atribuições",
      extras: "Extras da Vaga",
      incluirExtras: "Incluir Extras",
      minhasVagasCadastradas: "Minhas Vagas Cadastradas",
      desejaEnviarOsInvitesParaOsCandidatos: "Deseja enviar convites via e-mail para os candidatos selecionados?",
      enviarConvite: "Enviar Convite",
      enviarICS: "Enviar ICS?",
      organizador: "Nome do Organizador",
      tituloInvite: "Titulo do Convite",
      localInvite: "Local do Convite",
      duracaoInvite: "Duração da Reunião",
      assunto: "Assunto",
      convite_ics: "Convite de Reunião",
      escolhaTemplateEmail: "Escolha o template de e-mail",
      data: "Data",
      enviarEmail: "Enviar E-mail",
      organizadorEmail: "E-mail do organizador",
      enviarFeedbackNegativo: "Enviar Feedback Negativo",
      enviarFeedbackPositivo: "Enviar Feedback Positivo",
      enviarFeedback: "Enviar Feedback",
      selecione1CandidatoParaEnviarInvite: "Selecione pelo menos 1 candidato para enviar o convite de reunião!",
      inviteEnviadoComSucessoParaCandidatosSelecionados: "Convite enviado para o candidato selecionado",
      desejaEnviarFeedbackPositivoAosAprovados: "Deseja enviar feedback positivo aos candidatos aprovados?",
      desejaEnviarFeedbackNegativoAosNãoAprovados: "Deseja enviar feedback negativo aos candidatos não aprovados?",
      feedbackEnviadoComSucessoParaCandidatosAprovados: "Feedback positivo enviado com sucesso aos candidatos aprovados!",
      feedbackEnviadoComSucessoParaCandidatosNaoAprovados: "Feedback negativo enviado com sucesso aos candidatos não aprovados!",
      candidatosSelecionados: "Candidatos selecionados",
      sequencialDaVaga: "Sequencial da Vaga",
      stage: "Etapa",
      notApproved: "Não Segue",
      approve: "Aprovar",
      desejaAprovarEsteCandidato: "Deseja aprovar este candidado para seguir as próximas etapas?",
      desejaReprovarEsteCandidato: "Deseja reprovar este candidado para NÃO seguir as próximas etapas?",
      candidatoAprovado: "Candidato Aprovado!",
      candidatoReprovado: "Candidato Reprovado!",
      approved: "Segue",
      curriculoAnexo: "Currículo Anexo",
      download: "Download",
      aprovacao_gestor: "Aprovação do Gestor",
      estenderPrazo: "Estender o prazo até:",
      reabrirVaga: "Reiniciar Processo Seletivo",
      pontosRelevantes: "Pontos Relevantes",
      especialidadesDeMercado: "Especialidades no Mercado",
      reabrirProcessoSeletivo: "Reiniciar Processo Seletivo",
      resumoDaVaga: "Resumo da Vaga",
      naoVisivelAoGestor: "Não vísivel ao requisitante",
      visivelAoGestor: "Visível ao requisitante",
      republicarVaga: "Republicar Vaga",
      naoRepublicarVaga: "Não Republicar Vaga",
      escolhaTemplateWhatsapp: "Escolha o template da mensagem a ser enviada",
      whatsappEnviadoComSucessoParaCandidatosSelecionados: "Mensagem enviada via WhatsApp aos candidatos selecionados",
      enviarWhatsapp: "Enviar WhatsApp",
      desejaEnviarWhatsApp: "Deseja enviar mensagem via WhatsApp aos candidatos selecionados?",
      comentariosGestaoRS: "Comentários da Gestão de R&S",
      enviarGestor: "Enviar convite ao gestor?",
      escolhaTemplateGestor: "Escolha o template que será enviado ao gestor",
      selecioneQuestionario: "Selecione um questionário para ser aplicado antes da triagem",
      talent_bank: "Banco de Talentos",
      candidates_in_talent_bank: "Candidatos no Banco de Talentos",
      apply_to_job: "Aplicar à Vaga",
      apply_to_another_job: "Aplicar para outra vaga",
      candidatosVinculadosComSucesso: "Candidatos aplicados com sucesso!",
      cv_bank: "Banco de Currículos",
      candidates_in_cv_bank: "Candidatos no Banco de Curriculos",
      available_candidates: "Candidatos Disponíveis",
      dataDeAdmissao: "Data de Admissão",
      compartilhar: "Compartilhar",
      publiqueAVagaNoSitePrimeiro: "Para compartilhar nas redes, você precisa primeiro compartilhar a vaga no site.",
      verificarDetalhes: "Verificar Detalhes",
      requisitadaEm: "Requisitada em",
      vagaAPreencher: "Vaga a preencher",
      emailParaContatoDosCandidatos: "Email para contato dos candidatos",
      telefoneParaContatoDosCandidatos: "Telefone para contato dos candidatos",
      firstColor: "Cor 1",
      secondColor: "Cor 2",
      thirdColor: "Cor 3",
      url: "URL",
      meuPerfil: "Meu Perfil",
      cadastroHorariosDeTrabalho: "Cadastro de Horários de Trabalho",
      approverAt: "Aprovado em",
      conheca: "Conheça",
      documentacao: "Documentação",
      administracao: "ADMINISTRAÇÃO",
      dashboard: "Dashboard",
      aprovacoes: "Aprovações",
      geral: "Geral",
      empresas: "Empresa",
      centroDeCusto: "Centro de custo",
      cargos: "Cargos",
      templateDeCargos: "Templates de Cargo",
      templateDeEmails: "Templates de Email",
      workload: "Horários de Trabalho",
      licencasContratadas: "Licenças Contratadas",
      licencasUtilizadas: "Licenças Utilizadas",
      licencasDisponiveis: "Licenças Disponíveis",
      password: "Senha",
      passwordConfirm: "Confirmação de senha",
      confirm: "Confirmar",
      requisitante: "Requisitante",
      imprimir: "Imprimir",
      admin: "Administrador"
    }
  },
  en: {
    route: {
      dashboard: "Dashboard",
      vagas: "Jobs",
      vagasInternas: "Internal vacancies",
      aprovacoes: "Approvals",
      requisitarVaga: "Order job",
      selecao: "Selection",
      questionario: "Questionnaires",
      documentacao: "Documentation",
      geral: "General",
      empresas: "Companies",
      estabelecimentos: "Establishments",
      centroDeCusto: "Cost center",
      recrutadores: "Recruiters",
      gestoresDeArea: "Area Managers",
      cargos: "Positions",
      templateDeCargos: "Templates of Position",
      conheca: "Meet",
      questionarios: "Questionnaires",
      adicionarQuestionario: "Add Quiz",
      editarQuestionario: "Edit Survey",
      novoTemplate: "New Template",
      templates: "Templates",
      editarTemplate: "Edit Template",
      novoCargo: "New Position",
      editarCargo: "Edit Position",
      consultarCargo: "Consult Cargo",
      gerenciamentoGestoresArea: "Area Managers Management",
      gerenciamentoRecrutadores: "Management Recruiters",
      centroCusto: "Cost center",
      novosCentrosCusto: "New cost centers",
      editarCentroCusto: "Edit cost centers",
      consultarCentrosCusto: "Consulting cost centers",
      consultarEstabelecimento: "Consult Establishment",
      editarEstabelecimento: "Edit Establishment",
      novoEstabelecimento: "New establishment",
      consultarEmpresa: "Consult Company",
      editarEmpresa: "Edit Company",
      novaEmpresa: "New Company",
      edicaoCadastroEmpresas: "Registration Editing Companies",
      consultarTemplate: "Consult Template",
      vagasEmAprovacao: "Jobs in approval",
      consultarQuestionario: "Consult Questionnaire",
      aprovarVaga: "Approve job",
      publicarVaga: "Post vacancy",
      instalacao: "Installation",
      ativacao: "Activation",
      aSuaLicencaConfiguradaComBaseNaSuaPropostaComercial: "Your license is set based on their business proposal.",
      visualizarVaga: "View job",
      jobDescription: "Review",
      consultarEtapas: "Consult Steps",
      triagem: "Screening",
      login: "Login",
      usuarios: "Users",
      novosUsuarios: "New user",
      editarUsuario: "Edit User",
      consultarUsuario: "Consult User",
      novaLicenca: "New account",
      editarLicenca: "Edit Account",
      consultarLicenca: "Consult Account",
      reprovacoes: "Reproofs",
      vagasReprovadas: "Jobs Unapproved",
      integracoes: "Integrations",
      integracoesConfig: "Settings of Integrations",
      integracoesManager: "Integration Management",
      novaIntegracao: "New Integration",
      editarIntegracao: "Edit Integration",
      gestaoDaVaga: "Job Management",
      portal: "Portal",
      email_templates: "Email Templates",
      email_templatesAdd: "New Template Email",
      minhasVagas: "My Jobs",
      minhasVagasGerenciadas: "Managed Jobs",
      talent_bank: "Talent Bank",
      cv_bank: "CV Database",
      meuPerfil: "My profile",
      workload: "Working hours"
    },
    label: {
      admin: "Administrator",
      idiomaPortugues: "Portuguese",
      idiomaIngles: "English",
      idiomaEspanhol: "Spanish",
      vagas: "Jobs",
      aprovacao: "Approval",
      jobDescription: "Job Alignment",
      publicacao: "Publication",
      triagem: "Screening",
      selecao: "Selection",
      concluidas: "Completed",
      contratacoes: "Signings",
      ultimasContratacoes: "Latest signings",
      pesquisarAqui: "Search here",
      vagasSemRecrutador: "No Jobs Recruiter",
      selecione: "Select",
      titulo: "Title",
      candidatos: "Candidates",
      prazo: "Deadline",
      recrutador: "Recruiter",
      mostrando: "Showing",
      registros: "Records",
      deRegistros: "Records",
      semRecrutador: "No Recruiter",
      assumir: "Assume",
      definirRecrutador: "Set Recruiter",
      desejaResponsavelVaga: "Are you sure you want to be responsible for the job?",
      nao: "No",
      sim: "Yes",
      todosDireitos: "All rights reserved",
      desenvolvidoPor: "Developed by",
      fechar: "Close",
      ate: "Up until",
      filtros: "Filters",
      de: "In",
      filtroDataNaoInformadoCorretamente: "Date filter not entered correctly",
      copyright: "Copyright",
      nenhumRegistroEncontrado: "No records found ",
      pagina: "Page",
      vagasCadastradas: "Members Jobs",
      procurarVaga: "Find job",
      ordenar: "Order",
      ordenarCrescente: "Sort Ascending",
      ordenarDecrescente: "Sort Descending",
      nomeDaVaga: "Name of Job",
      dataDeConclusao: "Date of the conclusion",
      area: "Area",
      solicitante: "Requester",
      dataDeAbertura: "Opening date",
      pesquisePorRecrutador: "Search for recruiter",
      pesquisePorArea: "Search by area",
      dataFinalNaoPodeSerMenuDataInicial: "End Date can not be less than the initial date",
      nenhumResultadoComFiltroInformado: "No Results With the filter informed",
      todas: "All",
      canceladas: "Canceled",
      definirTransferirRecrutador: "Set / Transfer recruiter",
      cancelarVaga: "Cancel job",
      abertura: "Opening",
      conclusao: "Conclusion",
      salario: "Salary",
      cancelado: "Canceled",
      finalizado: "Finished",
      acombinar: "The Combine",
      naoInformado: "Uninformed",
      temCertezaCancelarVaga: "Are you sure you want to cancel the job?",
      porFavorInformeMotivo: "Please enter the reason.",
      vagaCanceladaComSucesso: "Job successfully canceled!",
      naoFoiPossivelCancelarVaga: "Could not cancel the job!",
      sucesso: "Success",
      ocorreuUmErro: "An error has occurred",
      informacoes: "Information",
      detalhesDaVaga: "Job details",
      linkPublico: "Link Public",
      tituloDaVaga: "Job title",
      vagasDisponiveis: "Available vacancies",
      NomeDaEmpresa: "Company Name",
      vaga: "Wave",
      anunciadaEm: "Announced in",
      expiraEm: "Expires in",
      aprovada: "Approved",
      reprovada: "Deprecated",
      vagaReprovadaPor: "Vacancy reproved by",
      vagaAprovadaPor: "Vacancy approved by",
      em: "In",
      observacao: "Note",
      descricaoDaVaga: "Job Description",
      requisitos: "Technical skills Essential",
      competenciasComportamentais: "Behavioral skills",
      diferenciais: "Differentials",
      beneficios: "Benefits",
      regime: "Regime",
      sobreAEmpresa: "About the company",
      cadastroQuestionarioClassificatorios: "Register questionnaires Qualifying",
      consulteQuestionarioParaVerQuestoes: "See a questionnaire to see issues",
      cadastrar: "Register",
      nome: "Name",
      visualizar: "To view",
      editar: "To edit",
      deletar: "Delete",
      acoes: "Actions",
      desejaDeletarRegistro: "You want to delete the record?",
      essaOperacaoNaoPodeSerDesfeita: "This operation can not be undone",
      perguntas: "Questions",
      voltar: "Come back",
      salvar: "To save",
      registroExcluidoComSucesso: "Successfully deleted record!",
      registroSalvoComSucesso: "Registration successfully saved!",
      pergunta: "Question",
      alternativa: "Alternative",
      alternativaCorreta: "Right Alternative?",
      adicionarAlternativa: "Add alternative",
      registroAtualizadoComSucesso: "Successfully updated record!",
      atencao: "Attention",
      perguntaPrecisaTerNoMinimo1Alternativa: "The question must have at least one alternative!",
      NãoEPossivelDeletarAAlternativaJaFoiUsadaResposta: "You can not delete! The alternative has been used in response.",
      nova: "New",
      questionario: "Questionnaires",
      cadastroDeTemplate: "Template Registration",
      cargo: "Office",
      descricao: "Description",
      diferencial: "Differential",
      complementos: "Add-ons",
      tipoDeAnuncio: "Type of ad",
      interno: "Internal",
      externo: "External",
      ambos: "Both",
      regimeContratacao: "Regime for Hiring",
      clt: "Effective",
      pj: "PJ",
      estagiario: "Intern",
      temporario: "Temporary",
      freelancer: "Free-Lancer",
      trainee: "Trainee",
      apprentice: "Apprentice",
      beneficio: "Benefit",
      incluirBeneficios: "Benefits include",
      naoFoiPossivelBuscarInformacoesReferencesAEmpresa: "Could not fetch the information related to the Company",
      naoFoiPossivelBuscarInformacoesReferencesAoCliente: "Could not fetch the information related to the Customer",
      cadastroTemplates: "Templates Registration",
      consultarEtapas: "Consult Steps",
      cadastroDeCargos: "Registration Offices",
      gerenciamentoDeUsuarios: "User Management",
      selecioneUsuarios: "Select users",
      gestores: "Managers",
      pesquisar: "Search",
      pesquisarUsuariosPlataforma: "Search for users on the platform",
      suaLicencaEstaLimitada: "Your license is limited to",
      gestorDeArea: "{S} Manager area.",
      usuariosDisponiveis: "Available users",
      matricula: "Registration",
      email: "Email",
      usuariosCadastrados: "Registered users",
      selecionar: "Select",
      trocar: "To exchange",
      cancelarTroca: "Cancel Exchange",
      jaAdicionado: "Already added!",
      usuarioAdicionadoComSucesso: "User successfully added!",
      naoFoiPossivelAdicionarUsuarioSelecionado: "Could not add the selected user",
      eNecessarioAoMenosUmUsuario: "You need at least one user",
      usuarioRemovidoComSucesso: "User successfully removed!",
      naoFoiPossivelRemoverUsuarioSelecionado: "Could not remove the selected user.",
      gerenciamentoRecrutadores: "Management Recruiters",
      recrutadores: "Recruiters",
      recrutadorS: "Recruiter {s}",
      recrutadorAdicionadoComSucesso: "Recruiter successfully added!",
      naoFoiPossivelAdicionarRecrutadorSelecionado: "Could not add the selected recruiter.",
      naoFoiPossivelRemoverRecrutadorSelecionado: "Could not remove the selected recruiter.",
      recrutadorRemovidoComSucesso: "Recruiter successfully removed!",
      selecioneUmOutroRecrutadorParaTrocarComORecrutadorASerRemovido: "Select another recruiter to exchange with the recruiter to be removed.",
      existemVagasVinculadasAEsseRecrutador: "There are places linked to that recruiter!",
      usuariosRecrutadores: "Recruiters users",
      desejaRealizarATrocaDeAlgumUsuario: "You want to perform the exchange of a member?",
      cadastroCentroCusto: "Cost center Registration",
      centroCusto: "Cost center",
      departamento: "Department",
      aprovador: "Approving",
      visualizacaoCadastroCentroCusto: "Register display Cost Center",
      cadastroEstabelecimento: "Establishments Registration",
      visualizacaoCadastroEstabelecimento: "Establishments Registration View",
      edicaoCadastroEstabelecimento: "Establishments Registry editing",
      cidade: "City",
      estado: "State",
      cadastroEmpresas: "Party Information",
      mensagemCadastroEmpresa: "The company has registration information for the HumanFlow, and are not consistent with the company registration on the platform",
      logotipo: "Logo",
      aImagemDeveTer: "The image must have",
      eSerEm: "And be in",
      informacoesEmpresa: "Company information",
      informacoesEstabelecimento: "Establishment information",
      cep: "Zip code",
      endereco: "Address",
      numero: "Number",
      complemento: "Complement",
      bairro: "Neighborhood",
      tamanhoFotoEmpresa2mb: "The size of the picture of the company must be a maximum of 2MB",
      visualizacaoCadastroEmpresas: "Register View Companies",
      imagemEmpresaAtualizadaComSucesso: "Image company updated successfully!",
      semPermissaoEditarArquivo: "No permission to edit files.",
      arrasteSolteOrderDesejadaDepoisCliqueBotao: "Drag and drop the desired order, then click the",
      salvarOrdenacao: "Save Order",
      adicionarCampo: "Add Field",
      naoFoiPossivelCarregarAsEtapas: "Could not load Steps",
      configuracaoEtapa: "Step Configuration",
      tipo: "Type",
      comum: "Common",
      teste: "Test",
      entrevista: "Interview",
      etapas: "Phases",
      template: "Template",
      requisitarVaga: "Order job",
      empresa: "Company",
      estabelecimentos: "Establishment",
      templateCargo: "Template Post",
      numeroVagas: "Number of vacancies",
      tipoVaga: "Kind of job",
      reposicao: "Replacement",
      informacoesCargo: "Cargo Information",
      observacoes: "Comments",
      digiteAqui: "Type here",
      exibirSalario: "View Salary",
      cancelar: "Cancel",
      iniciar: "Start",
      centroCustoObrigatorio: "Cost center is required",
      estabelecimentoObrigatorio: "Establishment is required",
      empresaObrigatoria: "Company is required",
      tituloObrigatoria: "Title is required",
      requirementsObrigatoria: "Requrements is required",
      differentialObrigatoria: "Diferential is required",
      formationObrigatoria: "Formation is required",
      workplaceObrigatoria: "Workplace is required",
      vagasPendentesAprovacao: "Approval Pending Jobs",
      vagasInternas: "Internal vacancies",
      edicaoCadastroEmpresas: "Edit Company",
      AprovacaoDeVaga: "Job Approval",
      voceNaoEAprovadorDessaVaga: "You are not the approver of this vacancy",
      selecioneEmpresa: "Select Company",
      selecioneEstabelecimento: "Select the Establishment",
      selecioneCentroCusto: "Select the Cost Center",
      estabelecimento: "Establishment",
      valor: "Value",
      Aprovar: "To approve",
      Reprovar: "Disapprove",
      vagaSemRecrutador: "Without job recruiter!",
      voceNaoERecrutadorDessaVaga: "You are not the recruiter this job",
      erro: "Mistake",
      segmentosDeAtuacao: "Business Areas",
      nenhumCandidatoEstagioFinal: "No candidate is in the final stage",
      perfilDoCandidato: "Candidate Profile",
      informacoesDeMercado: "Market Information",
      priorizaCandidadosInternos: "Prioritizes Internal Candidates",
      observacoesAdicionais: "Additional Remarks",
      finalizar: "End",
      erroCarregarPlataformas: "Error loading platforms",
      vagaPostadaComSucesso: "Job posted with Success",
      publicarVaga: "Post a new job",
      publicado: "Published",
      definir: "To define",
      emEdicao: "Edition",
      emRecrutamento: "In Recruitment",
      aguardandoPublicacao: "Awaiting Publication",
      emAprovacao: "On approval",
      umaVagaAPreencher: "A vacancy to fill",
      vagasAPreencher: "Vacancies to be filled",
      candidatosAprovados: "Approved candidates",
      telefone: "Telephone",
      reprovacao: "Reproof",
      vagaCanceladaPor: "Job canceled by",
      buscamosPessoasCom: "We seek people with",
      segmento: "Segment",
      quantidade: "Amount",
      informacoesAdicionais: "Additional Remarks",
      perfil: "Profile",
      voceTem: "You have",
      vagasEmAprovacao: "Vacancies in approval",
      vagaCancelada: "Canceled job",
      vagaConcluida: "Vacancy Completed",
      observacaoAprovador: "Note approver",
      marcarTodosCandidatos: "Mark All Candidates",
      moverPara: "Move To",
      aplicados: "Applied",
      selecionados: "Selected",
      desclassificados: "Disqualified",
      mensagem: "Message",
      selecioneAoMenosUmRegistro: "Please select at least one record",
      primeiraPagina: "First page",
      ultimaPagina: "Last page",
      alerta: "Alert",
      selecioneAoMenosUmCandidatoParaProsseguir: "Please select at least one candidate before proceeding!",
      ocorreuUmErroAoCarregarInformacoesVaga: "An error occurred while loading the vacancy information.",
      atualizadoComSucesso: "Updated Successfully",
      candidatosAtualizadosComSucesso: "Candidates Updated Successfully!",
      ocorreuUmErroCarregarInformacoesCandidatos: "There was an error loading the information from candidates",
      ocorreuUmErroCarregarInformacoesCandidatosDesclassificados: "There was an error loading the information of Disqualified Candidates",
      mensagemEnviadaComSucesso: "Message sent successfully",
      ok: "OK",
      visualizarPerfil: "View Profile",
      anterior: "Previous",
      proximo: "Next",
      naoHaCandidatosDesclassificados: "There are disqualified candidates!",
      naoHaCandidatosSelecionados: "No selected candidates!",
      naoHaCandidatosAplicados: "No candidates applied!",
      conhecimentos: "Knowledge",
      cursos: "Courses",
      idiomas: "Languages",
      graduacaoEm: "Graduation in",
      educacao: "Education",
      a: "The",
      experienciaProfissional: "Professional experience",
      resumo: "Resume",
      curriculo: "Curriculum",
      comentarios: "Comments",
      uf: "UF",
      pcd: "PCD",
      cargoDesejado: "Desired Job Title",
      fotoPerfil: "Photo Profile",
      buscaGeral: "General search",
      pesquisaEmVariasInformacoesDoPerfilDoCandidatoComoResumoeDescricao: "Research in several candidate profile information, such as summary and description",
      cargoPretendido: "Intended position",
      escolhaNaListaDeCargosDosCandidatosParaEssaVaga: "Choose from the list of positions of the candidates for this vacancy",
      escolhaNaListaDeCidadesDosCandidatosParaEssaVaga: "Choose the candidate cities list for this vacancy",
      escolhaNaListaDeEstadosDosCandidatosParaEssaVaga: "Choose from the list of states candidates for this vacancy",
      objetivo: "Objective",
      escolhaNaListaDeObjetivosDosCandidatosParaEssaVaga: "Choose from the list of goals of the candidates for this vacancy",
      habilidades: "Skills",
      escolhaNaListaDeHabilidadesDosCandidatosParaEssaVaga: "Choose the candidate list of skills for this job",
      idioma: "Language",
      graduacao: "University graduate",
      instituicao: "Institution",
      cursosLivres: "Free courses",
      escolhaNaListaDeCursosLivresDosCandidatosParaEssaVaga: "Choose from the list of candidates for this vacancy courses",
      escolhaNaListaDeInstituicoesDosCandidatosParaEssaVaga: "Choose from the list of institutions of candidates for this vacancy",
      escolhaNaListaDeCursosDosCandidatosParaEssaVaga: "Choose from the list of candidates for this vacancy courses",
      escolhaNaListaDeIdiomasDosCandidatosParaEssaVaga: "Choose the language list of candidates for this vacancy",
      ativacaoIniciadaEmBreveVoceReceberaoTokenPorEmail: "Activation started, soon you will get the token by email",
      ativacaoIniciadaComSucessoEmBreveVoceReceberaoTokenDeInstalacaoPorEmail: "Successfully started activation. Soon you will receive the installation token by email.",
      bemVindoAoHumanFlow: "Welcome to HumanFlow!",
      paraIniciaraAtivacaoPreenchaoFormularioAbaixo: "To start the activation, complete the form below",
      cnpj: "CNPJ",
      iniciarAtivacao: "Start Activation",
      aposIniciaraAtivacaoEmBreveVoceReceberaUmEmailComAsSeguintesInformacoes: "After starting the activation, soon you will receive an email with the following information",
      tokenParaInstalacao: "Token installation",
      quantidadedeRecrutadores: "Number of Recruiters",
      quantidadedeGestores: "Number of managers",
      essaConfiguracaoeRealizadaComBaseNaSuaPropostaComercial: "This setting is performed based on their business proposal.",
      bemVindoAoInstaladorDoHumanFlowVoceReceberaoTokenNoEmailInformadoNoCadastro: "Welcome to the installer HumanFlow! You will receive the token in the e-mail address provided at registration.",
      foiSelecionadoUmNumeroDeUsuariosMaiorQueoContratado: "It was selected a greater number of users who engaged!",
      selecioneAoMenosUmUsuario: "Please select at least one user",
      foiSelecionadoUmNumeroDeRecrutadoresMaiorQueoContratado: "It was selected a greater number of recruiters that hired!",
      selecioneAoMenosUmRecrutador: "Select at least one recruiter.",
      favorInformaroTokenHumanFlow: "Please enter the token HumanFlow",
      tokenInvalido: "Token invalid",
      instalacaoDoHumanFlow: "Installing HumanFlow",
      parabens: "Congratulations",
      aInstalacaoFoiConcluidaComCucesso: "The installation was completed successfully!",
      cliqueNoBotaoAbaixoParaAcessaroHumanFlow: "Click the button below to access the HumanFlow.",
      acessarHumanFlow: "Access HumanFlow",
      emCasoDeDuvidasEnvieUmEmailPara: "If you have questions, send an email to",
      instalacao: "Installation",
      sigaoProcessoPassoaPasso: "Follow the step by step process",
      ativacao: "Activation",
      digiteSeuToken: "Enter your Token",
      identificacaoDeRecrutadores: "Identification recruiters",
      aPesquisaeLimitadaEmAte20RegistrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado: "The search is limited to 20 records, then it may be necessary to refine the search to find the desired user.",
      usuariosPesquisados: "Searches users",
      pesquiseOsUsuariosNoCampoAcima: "Search users in the field above.",
      selecioneOsUsuariosAoLado: "Select users to side.",
      identificacaoDeGestoresDeArea: "Identification Area Managers",
      gestoresDeArea: "Area Managers",
      saoOsUsuariosQueIraoSolicitarVagasOuAprovarRequisicoes: "Are users who will apply for vacancies or approve requests",
      aPesquisaeLimitadaEmAte20registrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado: "The search is limited to 20 records, then it may be necessary to refine the search to find the desired user.",
      usuariosGestores: "Users Managers",
      clickEmFinalizarParaConcluiraInstalacao: "Click on Finish to complete the installation",
      instalando: "Installing",
      cadastrandoUsuarios: "Registering users",
      cadastrandoRecrutadores: "Registering recruiters",
      naoSePreocupeCasoNumeroDeLicencasEstejaDivergentePoisTambemePossivelAtualizarRecrutadoresGestoresAposaInstalacao: "Do not worry if the number of licenses is divergent, it is also possible to update the recruiters and managers after installation.",
      ouContateSeuExecutivoDeContas: "Or contact your account executive",
      emCasoDeDivergenciaOuDuvidasEntreEmContatoAtravesDoEmail: "In case of discrepancies or questions, please contact via e-mail",
      nenhumUsuarioEncontrado: "No user found!",
      usuarioEstaCadastradoComoRecrutador: "User is registered as a recruiter",
      usuarioEstaCadastradoComoGestor: "User is registered as a manager",
      removaPrimeirooUsuarioDosRecrutadoresCadastrados: "First remove the user from the registered recruiters.",
      removaPrimeirooUsuarioDosGestoresCadastrados: "First remove the user from the registered managers.",
      voceNaoTemPermissaoDeAcesso: "You do not have access permission!",
      naoFoiPossivelSalvarOIdioma: "Could not save the language!",
      cepInvalido: "CEP is not valid! Please fill out correctly.",
      logo: "Soon",
      redesSociais: "Social networks",
      selecionePeloMenos1CandidatoParaEnviaroTeste: "Select at least one candidate to send the test!",
      desejaEnviarOsTestesParaOsCandidatos: "You want to send the tests to the candidates?",
      testeEnviadoComSucessoParaCandidatosSelecionados: "Submitted successfully test for the selected candidates!",
      ocorreuUmErroAoAtualizarAsInformacoesDosCandidatos: "An error occurred while updating the information of the candidates",
      comentarioAdicionadoComSucesso: "Comment successfully added!",
      ocorreuUmErroAoRealizarUmaInteracao: "An error occurred while performing an interaction",
      nenhumComentarioParaExibir: "No comments to display!",
      aas: "At",
      candidatoAindaNaoRespondeuTeste: "Candidate has not yet answered the test!",
      candidatoAindaNaoRecebeuoTeste: "Candidate has not received the test!",
      recebidoEm: "Received in",
      finalizadoEm: "Finished in",
      status: "Status",
      resposta: "Answer",
      incorreta: "Incorrect",
      correta: "Correct",
      finalizarProcessoSeletivo: "Finish Selection Process?",
      processoFinalizadoComExito: "Process completed successfully!",
      processoSeletivo: "Selective process",
      dataFinal: "Final date",
      enviarTeste: "Send Test",
      descricaoNaoInformada: "Description Unknown!",
      nenhumaEtapaCadastradaCadastreEtapas: "No steps registered! Sign the steps.",
      arrasteSolteOrderDesejada: "Drag and drop the desired order.",
      vagasAbertas: "Slots",
      aSuaLicencaConfiguradaComBaseNaSuaPropostaComercial: "Your license is set based on their business proposal.",
      sair: "Go out",
      desejaSairHumanflow: "Humanflow want to exit?",
      naoFoiPossivelRealizarLogin: "Unable to login!",
      acesseHumanflow: "Access Humanflow",
      senha: "Password",
      entrar: "Log in",
      image: "Image",
      visualizacaoCadastroUsuario: "View User",
      cadastroUsuario: "User registration",
      cadastroLicencas: "User licenses",
      normal: "Normal",
      reprovado: "Disapproved",
      vagasReprovadas: "Jobs Unapproved",
      emBreve: "Coming soon",
      publicarNosSites: "Publish on the websites",
      publiqueAsVagasNosSitesPredefinidos: "Upload vacancies in predefined sites.",
      compartilharNasRedes: "Share on networks",
      compartilheAsVagasNasRedesCadastradas: "Share vacancies in registered networks.",
      integracoes: "Integrations",
      configureGerencieIntegracoesERP: "Configure and manage the integration with your ERP.",
      configurar: "To set up",
      configuracaoIntegracao: "Settings of Integrations",
      incluirIntegracoes: "Integrations include",
      gerenciar: "To manage",
      nomeErp: "Name ERP",
      linkWebService: "Web Service Link",
      sincronizarAutomaticamente: "Sync Automatically",
      integracaoHabilitada: "Integration Enabled",
      cadastroIntegracoes: "Registration Integrations",
      gerenciamentoIntegracao: "Integration Management",
      qualODadoASerImportado: "Which the data to be imported?",
      atualizarDados: "Update Data",
      cliqueParaAtualizarOsDados: "Click to update the data",
      habilitarImportacao: "Enable import",
      empresaPrincipal: "Company Main",
      filialPrincipal: "Main branch",
      testarConexao: "Test Connection",
      codigoERP: "ERP code",
      cadastroIntegracao: "Integration Register",
      selecioneOErp: "Select the ERP",
      informeOLinkWS: "Enter the web link service",
      webserviceConectadoComSucesso: "WebService successfully connected",
      naoFoiPossivelConectarNoWebService: "Could not connect to web service",
      exportarDadosParaOHumanflow: "Integrate data for Humanflow",
      dadosAtualizadosComSucesso: "Successfully updated data!",
      naoFoiPossivelAtualizarOsDados: "Could not update the data",
      exportarNovosFuncionarios: "Integrating employees",
      enviarEmailRequisitarDados: "Email to request data",
      enviarCandidatoParaOErp: "Send candidate for ERP",
      desejaEnviarEmailCandidatosAprovados: "Want to send email to the candidates requesting the outstanding information to hiring?",
      oCandidatoIraReceberEmailFormulario: "The candidate will receive an e-mail, which will have a link to access the form to report the required data.",
      emailEnviado: "Email sent",
      emailRespondido: "E-mail answered",
      candidatoExportado: "Integrated candidate",
      integracaoProtheus: "Integration Protheus",
      enviar: "Submit",
      selecioneUmaEmpresa: "Select company",
      genero: "Genre",
      masculino: "Male",
      feminino: "Feminine",
      idade: "Age",
      alterarVisibilidade: "Visibility change",
      naoFoiPossivelMudarAVisibilidadeDaVaga: "Could not change the visibility of job",
      gestaoDaVaga: "Job Management",
      visivelNoJobs: "Visible in Jobs",
      visibilidadeAlteradaComSucesso: "Visibility changed successfully",
      numeroDeVagas: "Number of vacancies",
      exibirNoPortalJobs: "View vacancy in the portal Jobs",
      aumentoDeQuadro: "Increase Table",
      motivo: "Reason",
      solicitadas: "Requested",
      publicadas: "Published",
      aprovadas: "Approved",
      substituicaoPorDemissao: "Replacement by Shutdown",
      substituicaoPorAlteracao: "Replacement for Change",
      substituicaoTemporaria: "Replacement by Pitch",
      budgeted: "It is planned in the budget?",
      competenciasDesejadas: "Technical Skills Desired",
      informacaoComplementar: "Additional information",
      aprendiz: "Apprentice",
      terceirizado: "Outsourced",
      configuraçoesDePublicacao: "Publish Settings",
      exibirValor: "Show value",
      aCombinar: "Negotiable",
      informacoesDoPortalDeCandidatos: "Portal information of candidates",
      foto1: "Photo 1",
      foto2: "Photo 2",
      foto3: "Photo 3",
      linkVideo: "Link Video",
      bannerPrincipal: "Banner main",
      website: "Web site",
      textoDasVagas: "Text of vacancies",
      twitter: "Twitter",
      linkedin: "Linkedin",
      instagram: "Instagram",
      youtube: "Youtube",
      facebook: "Facebook",
      horarioTrabalho: "Work schedule",
      formacao: "Academic education",
      vinculoAprovadores: "Approvers",
      numeroDaVaga: "Job number",
      AprovadoresDaVaga: "Approvers Job",
      cursosAdicionais: "Additional courses",
      cargaHoraria: "Monthly Hours",
      justificativa: "Rationale",
      temCertezaReabrirVaga: "Are you sure you want to reopen the job?",
      vagaReabertaComSucesso: "Job successfully reopened",
      naoFoiPossivelReabrirVaga: "Could not reopen the wave",
      localTrabalho: "Workplace",
      cadastroEmailTemplate: "Templates of registration Email",
      bodyEmail: "Email body",
      principaisAtribuicoes: "Main atributions",
      extras: "Job Extras",
      incluirExtras: "Extras include",
      minhasVagasCadastradas: "My Jobs Members",
      desejaEnviarOsInvitesParaOsCandidatos: "Want to send invitations via email to selected candidates?",
      enviarConvite: "Send Invitation",
      enviarICS: "Send ICS?",
      organizador: "Organizer name",
      tituloInvite: "Title Invitation",
      localInvite: "Invitation Location",
      duracaoInvite: "Duration of the meeting",
      assunto: "Subject matter",
      convite_ics: "Meeting invitation",
      escolhaTemplateEmail: "Select the template email",
      data: "Date",
      enviarEmail: "Send E-mail",
      organizadorEmail: "Email Organizer",
      enviarFeedbackNegativo: "Send Feedback Negative",
      enviarFeedbackPositivo: "Send Feedback Positive",
      enviarFeedback: "Send feedback",
      selecione1CandidatoParaEnviarInvite: "Select at least one candidate to send the meeting invitation!",
      inviteEnviadoComSucessoParaCandidatosSelecionados: "Invitation sent to the selected candidate",
      desejaEnviarFeedbackPositivoAosAprovados: "Want to send positive feedback to candidates?",
      desejaEnviarFeedbackNegativoAosNãoAprovados: "Want to send negative feedback to unsuccessful candidates?",
      feedbackEnviadoComSucessoParaCandidatosAprovados: "Positive feedback successfully sent to successful applicants!",
      feedbackEnviadoComSucessoParaCandidatosNaoAprovados: "Feedback negative successfully sent to unsuccessful candidates!",
      candidatosSelecionados: "Selected candidates",
      sequencialDaVaga: "Sequential job",
      stage: "Stage",
      notApproved: "Do not Follow",
      approve: "To approve",
      desejaAprovarEsteCandidato: "Want to approve this candidado to follow the next steps?",
      desejaReprovarEsteCandidato: "Do you want to disapprove this candidado NOT to follow the next steps?",
      candidatoAprovado: "Candidate Approved!",
      candidatoReprovado: "Failed candidate!",
      approved: "Follow",
      curriculoAnexo: "Annex curriculum",
      download: "Downloading",
      aprovacao_gestor: "Approval Manager",
      estenderPrazo: "Extend the deadline:",
      reabrirVaga: "Reset Selection Process",
      pontosRelevantes: "Relevant points",
      especialidadesDeMercado: "Specialties Market",
      reabrirProcessoSeletivo: "Reset Selection Process",
      resumoDaVaga: "Job Summary",
      naoVisivelAoGestor: "Not visible to the requester",
      visivelAoGestor: "Visible to the requester",
      republicarVaga: "Republish job",
      naoRepublicarVaga: "No job Republish",
      escolhaTemplateWhatsapp: "Select the message template to be sent",
      whatsappEnviadoComSucessoParaCandidatosSelecionados: "Message sent via WhatsApp to selected candidates",
      enviarWhatsapp: "Send WhatsApp",
      desejaEnviarWhatsApp: "Want to send a message via WhatsApp to selected candidates?",
      comentariosGestaoRS: "Reviews of R & S Management",
      enviarGestor: "Send invitation to the manager?",
      escolhaTemplateGestor: "Select the template that will be sent to the manager",
      selecioneQuestionario: "Select a questionnaire to be applied before the screening",
      talent_bank: "Talent Bank",
      candidates_in_talent_bank: "Candidates in the Talent Bank",
      apply_to_job: "Apply to job",
      apply_to_another_job: "Apply to another job",
      candidatosVinculadosComSucesso: "Successfully applied candidates!",
      cv_bank: "CV Database",
      candidates_in_cv_bank: "Candidates at the Bank of Curriculum",
      available_candidates: "Available candidates",
      dataDeAdmissao: "Admission date",
      compartilhar: "To share",
      publiqueAVagaNoSitePrimeiro: "To share the networks, you must first share the vacant site.",
      verificarDetalhes: "Check Details",
      requisitadaEm: "Requested in",
      vagaAPreencher: "Vacancy to fill",
      emailParaContatoDosCandidatos: "Email contact candidates",
      telefoneParaContatoDosCandidatos: "Contact telephone number of candidates",
      firstColor: "Color 1",
      secondColor: "Color 2",
      thirdColor: "Color 3",
      url: "URL",
      meuPerfil: "My profile",
      cadastroHorariosDeTrabalho: "Working Time Registration",
      approverAt: "Approved in",
      conheca: "Meet",
      documentacao: "Documentation",
      administracao: "ADMINISTRATION",
      dashboard: "Dashboard",
      aprovacoes: "Approvals",
      geral: "General",
      empresas: "Company",
      centroDeCusto: "Cost center",
      cargos: "Positions",
      templateDeCargos: "Templates of Position",
      templateDeEmails: "Email Templates",
      workload: "Working hours",
      licencasContratadas: "Contracted Licenses",
      licencasUtilizadas: "Licenses used",
      licencasDisponiveis: "Available licenses",
      password: "Password",
      passwordConfirm: "Password confirm",
      confirm: "confirm",
      requisitante: "Requester",
      imprimir: "Print"
    }
  },
  es: {
    route: {
      dashboard: "Tablero",
      vagas: "Trabajos",
      vagasInternas: "Vacantes internas",
      aprovacoes: "Aprobaciones",
      requisitarVaga: "Trabajo fin",
      selecao: "Selección",
      questionario: "Cuestionarios",
      documentacao: "Documentación",
      geral: "General",
      empresas: "Compañías",
      estabelecimentos: "Establecimientos",
      centroDeCusto: "Centro de coste",
      recrutadores: "Reclutadores",
      gestoresDeArea: "Gerentes de área",
      cargos: "Posiciones",
      templateDeCargos: "Plantillas de Posición",
      conheca: "Reunirse",
      questionarios: "Cuestionarios",
      adicionarQuestionario: "Añadir Cuestionario",
      editarQuestionario: "Editar encuesta",
      novoTemplate: "Nueva plantilla",
      templates: "Plantillas",
      editarTemplate: "Editar plantilla",
      novoCargo: "Nueva posición",
      editarCargo: "Editar posición",
      consultarCargo: "Consultar por carretera",
      gerenciamentoGestoresArea: "Área Managers",
      gerenciamentoRecrutadores: "Los reclutadores de gestión",
      centroCusto: "Centro de coste",
      novosCentrosCusto: "Nuevos centros de coste",
      editarCentroCusto: "Editar centros de coste",
      consultarCentrosCusto: "Consultar a los centros de coste",
      consultarEstabelecimento: "Consulte Establecimiento",
      editarEstabelecimento: "Editar Establecimiento",
      novoEstabelecimento: "Nuevo establecimiento",
      consultarEmpresa: "Consultar a la empresa",
      editarEmpresa: "Editar la empresa",
      novaEmpresa: "Nueva compañia",
      edicaoCadastroEmpresas: "Edición de registro Empresas",
      consultarTemplate: "Consulte la plantilla",
      vagasEmAprovacao: "Puestos de trabajo en la aprobación",
      consultarQuestionario: "Consultar Cuestionario",
      aprovarVaga: "Aprobar empleo",
      publicarVaga: "Puesto vacante",
      instalacao: "Instalación",
      ativacao: "Activación",
      aSuaLicencaConfiguradaComBaseNaSuaPropostaComercial: "Su licencia se establece en base a su propuesta de negocio.",
      visualizarVaga: "Ver empleo",
      jobDescription: "Revisión",
      consultarEtapas: "Consulte Pasos",
      triagem: "Poner en pantalla",
      login: "Iniciar sesión",
      usuarios: "Usuarios",
      novosUsuarios: "Nuevo Usuario",
      editarUsuario: "Editar usuario",
      consultarUsuario: "Consultar usuario",
      novaLicenca: "Nueva Licencia",
      editarLicenca: "Editar Licencia",
      consultarLicenca: "Consult Licencia",
      reprovacoes: "Reprensiones",
      vagasReprovadas: "Puestos de trabajo no aprobados",
      integracoes: "Integraciones",
      integracoesConfig: "Ajustes de integraciones",
      integracoesManager: "Manejo de integración",
      novaIntegracao: "La nueva integración",
      editarIntegracao: "Editar Integración",
      gestaoDaVaga: "Administración de trabajos",
      portal: "Portal",
      email_templates: "Plantillas de correo electrónico",
      email_templatesAdd: "Nueva plantilla de correo electrónico",
      minhasVagas: "Mis trabajos",
      minhasVagasGerenciadas: "trabajos Gestionados",
      talent_bank: "Banco de talento",
      cv_bank: "Base de datos de CV",
      meuPerfil: "Mi perfil",
      workload: "Horas Laborales"
    },
    label: {
      admin: "Administrador",
      idiomaPortugues: "Portugués",
      idiomaIngles: "Inglés",
      idiomaEspanhol: "Español",
      vagas: "Trabajos",
      aprovacao: "Aprobación",
      jobDescription: "La alineación de empleo",
      publicacao: "Publicación",
      triagem: "Poner en pantalla",
      selecao: "Selección",
      concluidas: "Terminado",
      contratacoes: "Fichajes",
      ultimasContratacoes: "Los últimos fichajes",
      pesquisarAqui: "Busca aquí",
      vagasSemRecrutador: "No hay ofertas de empleo",
      selecione: "Seleccione",
      titulo: "Título",
      candidatos: "Candidatos",
      prazo: "Fecha límite",
      recrutador: "Reclutador",
      mostrando: "Demostración",
      registros: "Registros",
      deRegistros: "Registros",
      semRecrutador: "Sin reclutador",
      assumir: "Asumir",
      definirRecrutador: "Conjunto reclutador",
      desejaResponsavelVaga: "¿Seguro que quiere ser responsable del trabajo?",
      nao: "No",
      sim: "Si",
      todosDireitos: "Todos los derechos reservados",
      desenvolvidoPor: "Desarrollado por",
      fechar: "Cerca",
      ate: "Hasta",
      filtros: "Filtros",
      de: "En",
      filtroDataNaoInformadoCorretamente: "Filtro de la fecha no se ha introducido correctamente",
      copyright: "Derechos de autor",
      nenhumRegistroEncontrado: "No se encontraron registros ",
      pagina: "Página",
      vagasCadastradas: "Los miembros Jobs",
      procurarVaga: "Encuentra trabajo",
      ordenar: "Orden",
      ordenarCrescente: "Orden ascendente",
      ordenarDecrescente: "Orden descendiente",
      nomeDaVaga: "Nombre del Trabajo",
      dataDeConclusao: "Fecha de finalizacion",
      area: "Zona",
      solicitante: "Solicitante",
      dataDeAbertura: "Fecha de apertura",
      pesquisePorRecrutador: "Buscar reclutador",
      pesquisePorArea: "Buscar por área",
      dataFinalNaoPodeSerMenuDataInicial: "Fecha de finalización no puede ser inferior a la fecha de inicio",
      nenhumResultadoComFiltroInformado: "No hay resultados con el filtro informados",
      todas: "Todas",
      canceladas: "Cancelado",
      definirTransferirRecrutador: "Set / reclutador Transferencia",
      cancelarVaga: "Cancelar trabajo",
      abertura: "Apertura",
      conclusao: "Conclusión",
      salario: "Salario",
      cancelado: "Cancelado",
      finalizado: "Terminado",
      acombinar: "El Combine",
      naoInformado: "No informado",
      temCertezaCancelarVaga: "¿Está seguro de que desea cancelar el trabajo?",
      porFavorInformeMotivo: "Por favor, introduzca el motivo.",
      vagaCanceladaComSucesso: "Trabajo cancelado correctamente!",
      naoFoiPossivelCancelarVaga: "No se puede cancelar el trabajo!",
      sucesso: "Éxito",
      ocorreuUmErro: "Ocurrio un error",
      informacoes: "Información",
      detalhesDaVaga: "Detalles del trabajo",
      linkPublico: "Enlace público",
      tituloDaVaga: "Título profesional",
      vagasDisponiveis: "Vacantes disponibles",
      NomeDaEmpresa: "Nombre de la empresa",
      vaga: "Ola",
      anunciadaEm: "Anunciado en",
      expiraEm: "Expira en",
      aprovada: "Aprobado",
      reprovada: "Obsoleto",
      vagaReprovadaPor: "Vacante por reprobó",
      vagaAprovadaPor: "Vacante aprobado por",
      em: "En",
      observacao: "Nota",
      descricaoDaVaga: "Descripción del trabajo",
      requisitos: "Las habilidades técnicas esencial",
      competenciasComportamentais: "Habilidades de comportamiento",
      diferenciais: "Diferenciales",
      beneficios: "Beneficios",
      regime: "Régimen",
      sobreAEmpresa: "Sobre la empresa",
      cadastroQuestionarioClassificatorios: "Registro cuestionarios de clasificación",
      consulteQuestionarioParaVerQuestoes: "Ver un cuestionario para ver los temas",
      cadastrar: "Registrar",
      nome: "Nombre",
      visualizar: "Para ver",
      editar: "Para editar",
      deletar: "Eliminar",
      acoes: "Comportamiento",
      desejaDeletarRegistro: "¿Quieres eliminar el registro?",
      essaOperacaoNaoPodeSerDesfeita: "Esta operación no se puede deshacer",
      perguntas: "Preguntas",
      voltar: "Vuelve",
      salvar: "Ahorrar",
      registroExcluidoComSucesso: "Eliminado correctamente récord!",
      registroSalvoComSucesso: "Registro guardado correctamente!",
      pergunta: "Pregunta",
      alternativa: "Alternativa",
      alternativaCorreta: "Justo Alternativa?",
      adicionarAlternativa: "Agregar alternativa",
      registroAtualizadoComSucesso: "Actualizado correctamente récord!",
      atencao: "Atención",
      perguntaPrecisaTerNoMinimo1Alternativa: "La cuestión debe tener al menos una alternativa!",
      NãoEPossivelDeletarAAlternativaJaFoiUsadaResposta: "No se puede eliminar! La alternativa ha sido utilizado en la respuesta.",
      nova: "Nuevo",
      questionario: "Cuestionarios",
      cadastroDeTemplate: "Registro de plantilla",
      cargo: "Oficina",
      descricao: "Descripción",
      diferencial: "Diferencial",
      complementos: "Complementos",
      tipoDeAnuncio: "Tipo de anuncio",
      interno: "Interno",
      externo: "Externo",
      ambos: "Ambos",
      regimeContratacao: "Régimen de Contratación",
      clt: "Eficaz",
      pj: "PJ",
      estagiario: "Interno",
      temporario: "Temporal",
      freelancer: "Persona de libre dedicación",
      trainee: "Aprendiz",
      apprentice: "Aprendiz",
      beneficio: "Beneficio",
      incluirBeneficios: "Beneficios incluidos",
      naoFoiPossivelBuscarInformacoesReferencesAEmpresa: "No se pudo obtener la información relacionada con la Compañía",
      naoFoiPossivelBuscarInformacoesReferencesAoCliente: "No se pudo obtener la información relacionada con el Cliente",
      cadastroTemplates: "Plantillas de registro",
      consultarEtapas: "Consulte Pasos",
      cadastroDeCargos: "Oficinas de registro",
      gerenciamentoDeUsuarios: "Gestión de usuarios",
      selecioneUsuarios: "Seleccione los usuarios",
      gestores: "Administradores",
      pesquisar: "Buscar",
      pesquisarUsuariosPlataforma: "Búsqueda de usuarios en la plataforma",
      suaLicencaEstaLimitada: "Su licencia se limita a",
      gestorDeArea: "área S} {Manager.",
      usuariosDisponiveis: "Usuarios disponibles",
      matricula: "Registro",
      email: "Email",
      usuariosCadastrados: "Usuarios Registrados",
      selecionar: "Seleccione",
      trocar: "A cambio",
      cancelarTroca: "Cancelar Cambio",
      jaAdicionado: "¡ya agregado!",
      usuarioAdicionadoComSucesso: "Usuario añadido correctamente!",
      naoFoiPossivelAdicionarUsuarioSelecionado: "No se pudo agregar el usuario seleccionado",
      eNecessarioAoMenosUmUsuario: "Se necesita al menos un usuario",
      usuarioRemovidoComSucesso: "Usuario eliminado correctamente!",
      naoFoiPossivelRemoverUsuarioSelecionado: "No se pudo eliminar el usuario seleccionado.",
      gerenciamentoRecrutadores: "Los reclutadores de gestión",
      recrutadores: "Reclutadores",
      recrutadorS: "Reclutador {s}",
      recrutadorAdicionadoComSucesso: "Reclutador añadido correctamente!",
      naoFoiPossivelAdicionarRecrutadorSelecionado: "No se pudo añadir el reclutador seleccionado.",
      naoFoiPossivelRemoverRecrutadorSelecionado: "No se pudo eliminar el reclutador seleccionado.",
      recrutadorRemovidoComSucesso: "Reclutador eliminado correctamente!",
      selecioneUmOutroRecrutadorParaTrocarComORecrutadorASerRemovido: "Seleccione otro reclutador para intercambiar con el reclutador para ser eliminado.",
      existemVagasVinculadasAEsseRecrutador: "Hay lugares vinculados a ese reclutador!",
      usuariosRecrutadores: "Usuarios reclutadores",
      desejaRealizarATrocaDeAlgumUsuario: "Que desea realizar el intercambio de un miembro?",
      cadastroCentroCusto: "Registro de centros de coste",
      centroCusto: "Centro de coste",
      departamento: "Departamento",
      aprovador: "Aprobatorio",
      visualizacaoCadastroCentroCusto: "Registro pantalla Coste Centro",
      cadastroEstabelecimento: "Registro de establecimientos",
      visualizacaoCadastroEstabelecimento: "Registro de Establecimientos Ver",
      edicaoCadastroEstabelecimento: "Establecimientos Registro de edición",
      cidade: "Ciudad",
      estado: "Estado",
      cadastroEmpresas: "Información del partido",
      mensagemCadastroEmpresa: "La empresa cuenta con la información de registro para el HumanFlow, y no son consistentes con el registro de la empresa en la plataforma",
      logotipo: "Logo",
      aImagemDeveTer: "La imagen debe tener",
      eSerEm: "Y estar en",
      informacoesEmpresa: "Información de la empresa",
      informacoesEstabelecimento: "Información de establecimiento",
      cep: "Código postal",
      endereco: "Habla a",
      numero: "Número",
      complemento: "Complemento",
      bairro: "Barrio",
      tamanhoFotoEmpresa2mb: "El tamaño de la imagen de la empresa debe ser un máximo de 2 MB",
      visualizacaoCadastroEmpresas: "Registro Ver Empresas",
      imagemEmpresaAtualizadaComSucesso: "Imagen de la empresa actualizado correctamente!",
      semPermissaoEditarArquivo: "No tiene permiso para editar archivos.",
      arrasteSolteOrderDesejadaDepoisCliqueBotao: "Arrastre y suelte el orden deseado, a continuación, haga clic en el",
      salvarOrdenacao: "Guardar Orden",
      adicionarCampo: "Agregue campo",
      naoFoiPossivelCarregarAsEtapas: "No se pudo cargar Pasos",
      configuracaoEtapa: "Configuración paso",
      tipo: "Tipo",
      comum: "Común",
      teste: "Prueba",
      entrevista: "Entrevista",
      etapas: "Etapas",
      template: "Modelo",
      requisitarVaga: "Trabajo fin",
      empresa: "Empresa",
      estabelecimentos: "Establecimiento",
      templateCargo: "Plantilla del anuncio",
      numeroVagas: "Numero de vacantes",
      tipoVaga: "Tipo de trabajo",
      reposicao: "Reemplazo",
      informacoesCargo: "Información sobre la carga",
      observacoes: "Comentarios",
      digiteAqui: "Digite aquí",
      exibirSalario: "Ver Salario",
      cancelar: "Cancelar",
      iniciar: "Comienzo",
      centroCustoObrigatorio: "Se requiere de centros de coste",
      estabelecimentoObrigatorio: "Se requiere el establecimiento",
      empresaObrigatoria: "Se requiere Compañía",
      tituloObrigatoria: "Se requiere titulo",
      requirementsObrigatoria: "Se requiere requisitos",
      differentialObrigatoria: "Se requiere diferenciales",
      formationObrigatoria: "Se requiere formación académica",
      workplaceObrigatoria: "Se requiere lugar de trabajo",
      vagasPendentesAprovacao: "Aprobación de las tareas pendientes",
      vagasInternas: "Vacantes internas",
      edicaoCadastroEmpresas: "Editar la empresa",
      AprovacaoDeVaga: "Aprobación de empleo",
      voceNaoEAprovadorDessaVaga: "Usted no es el responsable de aprobación de esta vacante",
      selecioneEmpresa: "Seleccione la compañía",
      selecioneEstabelecimento: "Seleccione el Establecimiento",
      selecioneCentroCusto: "Seleccione el centro de coste",
      estabelecimento: "Establecimiento",
      valor: "Valor",
      Aprovar: "Aprobar",
      Reprovar: "Desaprobar",
      vagaSemRecrutador: "Sin reclutador trabajo!",
      voceNaoERecrutadorDessaVaga: "Usted no es el reclutador este trabajo",
      erro: "Error",
      segmentosDeAtuacao: "Areas de negocio",
      nenhumCandidatoEstagioFinal: "Ningún candidato está en la etapa final",
      perfilDoCandidato: "Perfil del candidato",
      informacoesDeMercado: "Información de mercado",
      priorizaCandidadosInternos: "Los candidatos internos Prime",
      observacoesAdicionais: "Observaciones adicionales",
      finalizar: "Final",
      erroCarregarPlataformas: "Plataformas de carga de error",
      vagaPostadaComSucesso: "Trabajo publicado con éxito",
      publicarVaga: "Publicar un nuevo trabajo",
      publicado: "Publicado",
      definir: "Definir",
      emEdicao: "Edición",
      emRecrutamento: "En Reclutamiento",
      aguardandoPublicacao: "Pendiente de publicación",
      emAprovacao: "En aprovacion",
      umaVagaAPreencher: "Una vacante de relleno",
      vagasAPreencher: "Puestos que corresponden",
      candidatosAprovados: "Los candidatos aprobados",
      telefone: "Teléfono",
      reprovacao: "Reprensión",
      vagaCanceladaPor: "Trabajo cancelado por",
      buscamosPessoasCom: "Buscamos personas con",
      segmento: "Segmento",
      quantidade: "Cantidad",
      informacoesAdicionais: "Observaciones adicionales",
      perfil: "Perfil",
      voceTem: "Tú tienes",
      vagasEmAprovacao: "Vacantes en la aprobación",
      vagaCancelada: "Trabajo cancelado",
      vagaConcluida: "Completado vacante",
      observacaoAprovador: "Nota aprobador",
      marcarTodosCandidatos: "Marcar todos los candidatos",
      moverPara: "Mover a",
      aplicados: "Aplicado",
      selecionados: "Seleccionado",
      desclassificados: "Descalificado",
      mensagem: "Mensaje",
      selecioneAoMenosUmRegistro: "Por favor, seleccione al menos un registro",
      primeiraPagina: "Primera pagina",
      ultimaPagina: "Última página",
      alerta: "Alerta",
      selecioneAoMenosUmCandidatoParaProsseguir: "Por favor, seleccione al menos un candidato antes de continuar!",
      ocorreuUmErroAoCarregarInformacoesVaga: "Se produjo un error al cargar la información de vacantes.",
      atualizadoComSucesso: "Actualizado correctamente",
      candidatosAtualizadosComSucesso: "Los candidatos actualizado correctamente!",
      ocorreuUmErroCarregarInformacoesCandidatos: "Hubo un error al cargar la información de los candidatos",
      ocorreuUmErroCarregarInformacoesCandidatosDesclassificados: "Hubo un error al cargar la información de los candidatos descalificados",
      mensagemEnviadaComSucesso: "Mensaje enviado correctamente",
      ok: "Okay",
      visualizarPerfil: "Ver perfil",
      anterior: "Anterior",
      proximo: "Próximo",
      naoHaCandidatosDesclassificados: "Hay candidatos descalificados!",
      naoHaCandidatosSelecionados: "Sin candidatos seleccionados!",
      naoHaCandidatosAplicados: "No se aplican los candidatos!",
      conhecimentos: "Conocimiento",
      cursos: "Cursos",
      idiomas: "Idiomas",
      graduacaoEm: "Graduación en",
      educacao: "Educación",
      a: "Los",
      experienciaProfissional: "Experiencia profesional",
      resumo: "Currículum",
      curriculo: "Plan de estudios",
      comentarios: "Comentarios",
      uf: "UF",
      pcd: "PCD",
      cargoDesejado: "Título del trabajo deseado",
      fotoPerfil: "Foto de perfil",
      buscaGeral: "Búsqueda general",
      pesquisaEmVariasInformacoesDoPerfilDoCandidatoComoResumoeDescricao: "La investigación en varias informaciones perfil del candidato, como resumen y descripción",
      cargoPretendido: "Posición deseada",
      escolhaNaListaDeCargosDosCandidatosParaEssaVaga: "Elija de la lista de posiciones de los candidatos para esta vacante",
      escolhaNaListaDeCidadesDosCandidatosParaEssaVaga: "En la lista de ciudades candidatas para esta vacante",
      escolhaNaListaDeEstadosDosCandidatosParaEssaVaga: "Elija de la lista de Estados candidatos a esta vacante",
      objetivo: "Objetivo",
      escolhaNaListaDeObjetivosDosCandidatosParaEssaVaga: "Elija de la lista de objetivos de los candidatos para esta vacante",
      habilidades: "Habilidades",
      escolhaNaListaDeHabilidadesDosCandidatosParaEssaVaga: "Elija la lista de candidatos de las habilidades para este trabajo",
      idioma: "Idioma",
      graduacao: "Graduado universitario",
      instituicao: "Institución",
      cursosLivres: "Cursos gratuitos",
      escolhaNaListaDeCursosLivresDosCandidatosParaEssaVaga: "Elija de la lista de candidatos a estos cursos de vacantes",
      escolhaNaListaDeInstituicoesDosCandidatosParaEssaVaga: "Elija de la lista de instituciones de candidatos para esta vacante",
      escolhaNaListaDeCursosDosCandidatosParaEssaVaga: "Elija de la lista de candidatos a estos cursos de vacantes",
      escolhaNaListaDeIdiomasDosCandidatosParaEssaVaga: "Elija la lista de idiomas de los candidatos a esta vacante",
      ativacaoIniciadaEmBreveVoceReceberaoTokenPorEmail: "La activación inicia, pronto obtendrá el token por correo electrónico",
      ativacaoIniciadaComSucessoEmBreveVoceReceberaoTokenDeInstalacaoPorEmail: "Iniciado con éxito la activación. Pronto recibirá la instalación token de correo electrónico.",
      bemVindoAoHumanFlow: "Bienvenido a HumanFlow!",
      paraIniciaraAtivacaoPreenchaoFormularioAbaixo: "Para iniciar la activación, complete el siguiente formulario",
      cnpj: "CNPJ",
      iniciarAtivacao: "Iniciar activación",
      aposIniciaraAtivacaoEmBreveVoceReceberaUmEmailComAsSeguintesInformacoes: "Después de iniciar la activación, en breve recibirá un correo electrónico con la siguiente información",
      tokenParaInstalacao: "La instalación de emergencia",
      quantidadedeRecrutadores: "Número de reclutadores",
      quantidadedeGestores: "Número de gestores",
      essaConfiguracaoeRealizadaComBaseNaSuaPropostaComercial: "Este ajuste se realiza en base a su propuesta de negocio.",
      bemVindoAoInstaladorDoHumanFlowVoceReceberaoTokenNoEmailInformadoNoCadastro: "Bienvenido al instalador HumanFlow! Recibirá la ficha en la dirección de correo electrónico proporcionada durante el registro.",
      foiSelecionadoUmNumeroDeUsuariosMaiorQueoContratado: "Fue seleccionado un número mayor de usuarios que participan!",
      selecioneAoMenosUmUsuario: "Por favor, seleccione al menos un usuario",
      foiSelecionadoUmNumeroDeRecrutadoresMaiorQueoContratado: "Fue seleccionado un número mayor de selección de personal que contrató!",
      selecioneAoMenosUmRecrutador: "Seleccionar al menos un reclutador.",
      favorInformaroTokenHumanFlow: "Por favor, introduzca el símbolo HumanFlow",
      tokenInvalido: "Ficha Invalida",
      instalacaoDoHumanFlow: "Instalación HumanFlow",
      parabens: "Felicidades",
      aInstalacaoFoiConcluidaComCucesso: "La instalación se ha completado correctamente!",
      cliqueNoBotaoAbaixoParaAcessaroHumanFlow: "Haga clic en el botón de abajo para acceder a la HumanFlow.",
      acessarHumanFlow: "Acceso HumanFlow",
      emCasoDeDuvidasEnvieUmEmailPara: "Si tiene alguna pregunta, envíe un correo electrónico a",
      instalacao: "Instalación",
      sigaoProcessoPassoaPasso: "Seguir el proceso paso a paso",
      ativacao: "Activación",
      digiteSeuToken: "Introduzca su Token",
      identificacaoDeRecrutadores: "Reclutadores de identificación",
      aPesquisaeLimitadaEmAte20RegistrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado: "La búsqueda se limita a 20 registros, entonces puede ser necesario refinar la búsqueda para encontrar el usuario deseado.",
      usuariosPesquisados: "Búsquedas usuarios",
      pesquiseOsUsuariosNoCampoAcima: "Buscar usuarios en el campo anterior.",
      selecioneOsUsuariosAoLado: "Seleccione los usuarios a lado.",
      identificacaoDeGestoresDeArea: "Gerentes de área de identificación",
      gestoresDeArea: "Gerentes de área",
      saoOsUsuariosQueIraoSolicitarVagasOuAprovarRequisicoes: "Son los usuarios que deben pagar los vacantes o aprobar las solicitudes",
      aPesquisaeLimitadaEmAte20registrosEntaoPodeSerNecessarioRefinaraBuscaParaEncontraroUsuarioDesejado: "La búsqueda se limita a 20 registros, entonces puede ser necesario refinar la búsqueda para encontrar el usuario deseado.",
      usuariosGestores: "Los gerentes de los usuarios",
      clickEmFinalizarParaConcluiraInstalacao: "Haga clic en Finalizar para completar la instalación",
      instalando: "Instalación",
      cadastrandoUsuarios: "Registro de usuarios",
      cadastrandoRecrutadores: "El registro de los reclutadores",
      naoSePreocupeCasoNumeroDeLicencasEstejaDivergentePoisTambemePossivelAtualizarRecrutadoresGestoresAposaInstalacao: "No se preocupe si el número de licencias es divergente, también es posible actualizar los reclutadores y gerentes después de la instalación.",
      ouContateSeuExecutivoDeContas: "O póngase en contacto con su ejecutivo de cuenta",
      emCasoDeDivergenciaOuDuvidasEntreEmContatoAtravesDoEmail: "En caso de discrepancia o pregunta, por favor contacto a través del correo electrónico",
      nenhumUsuarioEncontrado: "¡Usuario no encontrado!",
      usuarioEstaCadastradoComoRecrutador: "Usuario se ha registrado como un reclutador",
      usuarioEstaCadastradoComoGestor: "El usuario se ha registrado como un administrador",
      removaPrimeirooUsuarioDosRecrutadoresCadastrados: "Primero eliminar al usuario de los reclutadores registrados.",
      removaPrimeirooUsuarioDosGestoresCadastrados: "Primero eliminar al usuario de los gestores registrados.",
      voceNaoTemPermissaoDeAcesso: "Usted no tiene permiso de acceso!",
      naoFoiPossivelSalvarOIdioma: "No se pudo guardar el idioma!",
      cepInvalido: "CEP no es válido! Por favor, rellene correctamente.",
      logo: "Pronto",
      redesSociais: "Redes sociales",
      selecionePeloMenos1CandidatoParaEnviaroTeste: "Seleccionar al menos un candidato para enviar la prueba!",
      desejaEnviarOsTestesParaOsCandidatos: "Que desea enviar las pruebas a los candidatos?",
      testeEnviadoComSucessoParaCandidatosSelecionados: "Enviado correctamente prueba para los candidatos seleccionados!",
      ocorreuUmErroAoAtualizarAsInformacoesDosCandidatos: "Se produjo un error al actualizar la información de los candidatos",
      comentarioAdicionadoComSucesso: "Comentario añadido correctamente!",
      ocorreuUmErroAoRealizarUmaInteracao: "Se ha producido un error al realizar una interacción",
      nenhumComentarioParaExibir: "No hay comentarios para mostrar!",
      aas: "A",
      candidatoAindaNaoRespondeuTeste: "Candidato aún no ha respondido a la prueba!",
      candidatoAindaNaoRecebeuoTeste: "Candidato no ha recibido la prueba!",
      recebidoEm: "Recibida en",
      finalizadoEm: "En acabados",
      status: "Estado",
      resposta: "Responder",
      incorreta: "Incorrecto",
      correta: "Correcto",
      finalizarProcessoSeletivo: "Proceso de Selección de acabado?",
      processoFinalizadoComExito: "Proceso completado con éxito!",
      processoSeletivo: "Proceso de selección",
      dataFinal: "Fecha final",
      enviarTeste: "Enviar prueba",
      descricaoNaoInformada: "Descripción Desconocido!",
      nenhumaEtapaCadastradaCadastreEtapas: "No hay pasos registrados! Firmar los pasos.",
      arrasteSolteOrderDesejada: "Arrastrar y soltar el orden deseado.",
      vagasAbertas: "Ranuras",
      aSuaLicencaConfiguradaComBaseNaSuaPropostaComercial: "Su licencia se establece en base a su propuesta de negocio.",
      sair: "Fuera",
      desejaSairHumanflow: "Humanflow desea salir?",
      naoFoiPossivelRealizarLogin: "¡Incapaz de iniciar sesión!",
      acesseHumanflow: "Acceso Humanflow",
      senha: "Contraseña",
      entrar: "Iniciar sesión",
      image: "Imagen",
      visualizacaoCadastroUsuario: "Vista de usuarios",
      cadastroUsuario: "Registro de usuario",
      cadastroLicencas: "Registro de licencias",
      normal: "Normal",
      reprovado: "Rechazado",
      vagasReprovadas: "Puestos de trabajo no aprobados",
      emBreve: "Pronto",
      publicarNosSites: "Publicar en los sitios web",
      publiqueAsVagasNosSitesPredefinidos: "Sube vacantes en sitios predefinidos.",
      compartilharNasRedes: "Compartir en redes",
      compartilheAsVagasNasRedesCadastradas: "Acciones vacantes en las redes registradas.",
      integracoes: "Integraciones",
      configureGerencieIntegracoesERP: "Configurar y gestionar la integración con su ERP.",
      configurar: "Para configurar",
      configuracaoIntegracao: "Ajustes de integraciones",
      incluirIntegracoes: "Integraciones incluyen",
      gerenciar: "Administrar",
      nomeErp: "Nombre ERP",
      linkWebService: "Web del Servicio de Enlace",
      sincronizarAutomaticamente: "Sincroniza automáticamente",
      integracaoHabilitada: "La integración Habilitado",
      cadastroIntegracoes: "Las integraciones de registro",
      gerenciamentoIntegracao: "Manejo de integración",
      qualODadoASerImportado: "Que los datos a importar?",
      atualizarDados: "Actualizar datos",
      cliqueParaAtualizarOsDados: "Haga clic aquí para actualizar los datos",
      habilitarImportacao: "Permitir la importación",
      empresaPrincipal: "Principal de la empresa",
      filialPrincipal: "Rama principal",
      testarConexao: "Conexión de prueba",
      codigoERP: "Código ERP",
      cadastroIntegracao: "Integración Registro",
      selecioneOErp: "Seleccione el ERP",
      informeOLinkWS: "Entrar en el servicio de enlace de la web",
      webserviceConectadoComSucesso: "WebService conectado correctamente",
      naoFoiPossivelConectarNoWebService: "No se pudo conectar al servicio web",
      exportarDadosParaOHumanflow: "Integrar los datos para Humanflow",
      dadosAtualizadosComSucesso: "Actualizado correctamente los datos!",
      naoFoiPossivelAtualizarOsDados: "No se pudo actualizar los datos",
      exportarNovosFuncionarios: "Los empleados que integran",
      enviarEmailRequisitarDados: "Correo electrónico a los datos de solicitud",
      enviarCandidatoParaOErp: "Enviar candidato para ERP",
      desejaEnviarEmailCandidatosAprovados: "¿Quieres enviar correo electrónico a los candidatos que solicitan la información pendiente a la contratación?",
      oCandidatoIraReceberEmailFormulario: "El candidato recibirá un correo electrónico, que tendrá un enlace para acceder al formulario para comunicar los datos requeridos.",
      emailEnviado: "Email enviado",
      emailRespondido: "E-mail contestado",
      candidatoExportado: "Candidato integrada",
      integracaoProtheus: "La integración Protheus",
      enviar: "Enviar",
      selecioneUmaEmpresa: "Seleccione la compañía",
      genero: "Género",
      masculino: "Masculino",
      feminino: "Femenino",
      idade: "Años",
      alterarVisibilidade: "Cambio de visibilidad",
      naoFoiPossivelMudarAVisibilidadeDaVaga: "No se pudo cambiar la visibilidad de trabajo",
      gestaoDaVaga: "Administración de trabajos",
      visivelNoJobs: "Visible en Empleo",
      visibilidadeAlteradaComSucesso: "Visibilidad cambiado correctamente",
      numeroDeVagas: "Numero de vacantes",
      exibirNoPortalJobs: "Ver vacante en el portal de Empleo",
      aumentoDeQuadro: "Aumentar la tabla",
      motivo: "Razón",
      solicitadas: "Pedido",
      publicadas: "Publicado",
      aprovadas: "Aprobado",
      substituicaoPorDemissao: "Sustitución por apagado",
      substituicaoPorAlteracao: "Reemplazo para el Cambio",
      substituicaoTemporaria: "La sustitución por el lanzamiento",
      budgeted: "Se ha previsto en el presupuesto?",
      competenciasDesejadas: "Habilidades técnico deseado",
      informacaoComplementar: "Información complementaria",
      aprendiz: "Aprendiz",
      terceirizado: "Externalizado",
      configuraçoesDePublicacao: "Configuración de publicación",
      exibirValor: "Mostrar valor",
      aCombinar: "Negociable",
      informacoesDoPortalDeCandidatos: "Información del portal de candidatos",
      foto1: "1 foto",
      foto2: "Foto 2",
      foto3: "Foto 3",
      linkVideo: "Enlace vídeo",
      bannerPrincipal: "Principal bandera",
      website: "Sitio web",
      textoDasVagas: "Texto de las vacantes",
      twitter: "Gorjeo",
      linkedin: "Linkedin",
      instagram: "Instagram",
      youtube: "Youtube",
      facebook: "Facebook",
      horarioTrabalho: "Horario de trabajo",
      formacao: "Formación académica",
      vinculoAprovadores: "Aprobadores",
      numeroDaVaga: "Número de empleo",
      AprovadoresDaVaga: "Aprobadores de empleo",
      cursosAdicionais: "Cursos adicionales",
      cargaHoraria: "Horas mensuales",
      justificativa: "Razón fundamental",
      temCertezaReabrirVaga: "¿Está seguro de que desea volver a abrir el trabajo?",
      vagaReabertaComSucesso: "Trabajo volvió a abrir con éxito",
      naoFoiPossivelReabrirVaga: "No se pudo abrir de nuevo la ola",
      localTrabalho: "Lugar de trabajo",
      cadastroEmailTemplate: "Plantillas de correo electrónico de registro",
      bodyEmail: "Cuerpo del correo electronico",
      principaisAtribuicoes: "ATRIBUCIONES principales",
      extras: "Trabajo Extras",
      incluirExtras: "Los extras incluyen",
      minhasVagasCadastradas: "Mis miembros Empleo",
      desejaEnviarOsInvitesParaOsCandidatos: "¿Quieres enviar invitaciones por correo electrónico a los candidatos seleccionados?",
      enviarConvite: "Enviar invitacion",
      enviarICS: "Enviar ICS?",
      organizador: "Nombre del organizador",
      tituloInvite: "Invitación del título",
      localInvite: "Invitación Localización",
      duracaoInvite: "Duración de la reunión",
      assunto: "Tema en cuestion",
      convite_ics: "Invitación a una reunión",
      escolhaTemplateEmail: "Seleccione la plantilla de correo",
      data: "Fecha",
      enviarEmail: "Enviar correo electrónico",
      organizadorEmail: "Correo electrónico Organizador",
      enviarFeedbackNegativo: "Enviar voto negativo",
      enviarFeedbackPositivo: "Enviar Comentarios positivos",
      enviarFeedback: "Enviar retroalimentación",
      selecione1CandidatoParaEnviarInvite: "Seleccionar al menos un candidato para enviar la invitación a la reunión!",
      inviteEnviadoComSucessoParaCandidatosSelecionados: "Invitación enviada al candidato seleccionado",
      desejaEnviarFeedbackPositivoAosAprovados: "¿Quieres enviar retroalimentación positiva a los candidatos?",
      desejaEnviarFeedbackNegativoAosNãoAprovados: "¿Quieres enviar retroalimentación negativa a los candidatos no seleccionados?",
      feedbackEnviadoComSucessoParaCandidatosAprovados: "Retroalimentación positiva enviado con éxito a los candidatos!",
      feedbackEnviadoComSucessoParaCandidatosNaoAprovados: "Retroalimentación negativa ha enviado correctamente a los candidatos no seleccionados!",
      candidatosSelecionados: "Candidatos seleccionados",
      sequencialDaVaga: "Trabajo secuencial",
      stage: "Etapa",
      notApproved: "No sigas",
      approve: "Aprobar",
      desejaAprovarEsteCandidato: "¿Quieres aprobar el presente candidado a seguir los siguientes pasos?",
      desejaReprovarEsteCandidato: "¿Quieres rechazar este candidado a no seguir los siguientes pasos?",
      candidatoAprovado: "Candidato Aprobado!",
      candidatoReprovado: "Candidato fracasado!",
      approved: "Seguir",
      curriculoAnexo: "Anexo curricular",
      download: "Descargar",
      aprovacao_gestor: "Director de la aprobación",
      estenderPrazo: "Ampliar el plazo:",
      reabrirVaga: "Restablecer Proceso de Selección",
      pontosRelevantes: "Los puntos relevantes",
      especialidadesDeMercado: "Especialidades Mercado",
      reabrirProcessoSeletivo: "Restablecer Proceso de Selección",
      resumoDaVaga: "Resumen de trabajo",
      naoVisivelAoGestor: "No es visible para el solicitante",
      visivelAoGestor: "Visible al solicitante",
      republicarVaga: "Trabajo republicación",
      naoRepublicarVaga: "Sin Republish trabajo",
      escolhaTemplateWhatsapp: "Seleccione la plantilla de mensaje que se enviará",
      whatsappEnviadoComSucessoParaCandidatosSelecionados: "Mensaje enviado a través de WhatsApp a los candidatos seleccionados",
      enviarWhatsapp: "Enviar WhatsApp",
      desejaEnviarWhatsApp: "¿Quieres enviar un mensaje por WhatsApp a los candidatos seleccionados?",
      comentariosGestaoRS: "Los comentarios de R & S Gestión",
      enviarGestor: "Enviar invitación al gerente?",
      escolhaTemplateGestor: "Seleccione la plantilla que se envía al gestor",
      selecioneQuestionario: "Seleccionar un cuestionario que se aplica antes de la proyección",
      talent_bank: "Banco de talento",
      candidates_in_talent_bank: "Los candidatos en el Banco de Talento",
      apply_to_job: "Aplica al trabajo",
      apply_to_another_job: "Aplica a otra oferta",
      candidatosVinculadosComSucesso: "Candidatos aplicado con éxito!",
      cv_bank: "Base de datos de CV",
      candidates_in_cv_bank: "Los candidatos en el Banco de Currículo",
      available_candidates: "Candidatos disponibles",
      dataDeAdmissao: "Fecha de admisión",
      compartilhar: "Para compartir",
      publiqueAVagaNoSitePrimeiro: "Para compartir las redes, primero debe compartir el sitio vacante.",
      verificarDetalhes: "Comprobar los detalles",
      requisitadaEm: "Solicitada en",
      vagaAPreencher: "Vacantes a llenar",
      emailParaContatoDosCandidatos: "Candidatos de correo electrónico de contacto",
      telefoneParaContatoDosCandidatos: "Teléfono de contacto de los candidatos",
      firstColor: "Color 1",
      secondColor: "Color 2",
      thirdColor: "Color 3",
      url: "URL",
      meuPerfil: "Mi perfil",
      cadastroHorariosDeTrabalho: "Registro de tiempo de trabajo",
      approverAt: "Aprobado en",
      conheca: "Reunirse",
      documentacao: "Documentación",
      administracao: "ADMINISTRACIÓN",
      dashboard: "Tablero",
      aprovacoes: "Aprobaciones",
      geral: "General",
      empresas: "Empresa",
      centroDeCusto: "Centro de coste",
      cargos: "Posiciones",
      templateDeCargos: "Plantillas de Posición",
      templateDeEmails: "Plantillas de correo electrónico",
      workload: "Horas Laborales",
      licencasContratadas: "Licencias Contratadas",
      licencasUtilizadas: "Licencias Usado",
      licencasDisponiveis: "Licencias Disponibles",
      password: "Contraseña",
      passwordConfirm: "Confirmación de contraseña",
      confirm: "confirme",
      requisitante: "Solicitante",
      imprimir: "Imprimir"
    }
  }
}
export default messages;
