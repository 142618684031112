<template>
    <div>
        <job-recruitment-candidate-detail 
            :candidate="jobCandidate" 
            :iterations='iterations' 
            @sendMessage="sendMessage" 
            @newiteration="newiteration"
            @anonymizeCandidate="anonymizeCandidate">
        </job-recruitment-candidate-detail>            
        <div class="col-xl-12">
            <div class="card text-dark-blue bg-flat-gray">
                <div class="card-body">
                    <h3 class="card-title">{{ $t('label.cv_bank') }}</h3>
                    <div class="row">                                 
                        <filter-candidates 
                            :objectivesOptions="objectivesOptions" 
                            :areasOptions="areasOptions" 
                            :typeFilter='typeFilter' 
                            :graduationsOptions="graduationsOptions"
                            :habilitiesOptions="habilitiesOptions" 
                            :languagesOptions="languagesOptions"
                            :experienceCompaniesOptions="experienceCompaniesOptions"
                            :experiencePositionsOptions="experiencePositionsOptions"
                            @filterCandidates="filterCandidates"
                            :key="'filter-candidates-' + currentId"
                            >
                        </filter-candidates>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="float-right mb-4">

                                        <button class="btn edit-btn" @click="loadRecruitment"><i class="fa fa-refresh"></i></button>
                                        
                                        <button class="btn select-btn color-white" type="button" :disabled="checkedItens.length <= 0 && totalSelected <= 0" @click="applyJobs">{{ $t('label.apply_to_job') }} <i class="fa fa-forward"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="informacoes">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{$t('label.tituloDaVaga')}}</th>
                                            <th scope="col">{{$t('label.vagasDisponiveis')}}</th>
                                            <th scope="col">{{$t('label.NomeDaEmpresa')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="jobObj">
                                        <tr v-if="jobObj.role_name != ''">
                                            <th scope="row">{{ jobObj.role_name }}</th>
                                            <td>{{ jobObj.quantity }} {{$t('label.vaga')}}{s}</td>
                                            <td>{{ jobObj.company ? jobObj.company.name : '' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-12 text-center">
                                    <span class="title-triagem"><i class="fa fa-user"></i> {{ $t('label.candidatos') }} <span>({{ totalPending + totalSelected + totalDeleted }})</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group float-left mr-3">
                                        <div class="input-group">
                                            <div class="form-check-inline form-check">
                                                <label for="marcarTodos" class="form-check-label mr-3">
                                                    <input type="checkbox" id="marcarTodos" v-model="checkalldisplayed" name="marcarTodos" value="option1" class="form-check-input">
                                                    <span class="checkmark"></span>{{ $t('label.marcarTodosCandidatos') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <candidate-list
                                        :candidate_list="candidatesList" 
                                        :selectedCandidate="selectedCandidatesList" 
                                        :deletedCandidate="deletedCandidatesList"
                                        :selectPending='checkalldisplayed && displayedId =="candidate"' 
                                        :selectDeleted="checkalldisplayed && displayedId =='out'"
                                        :selectSelected="(checkalldisplayed && displayedId =='selected')" 
                                        @notSelectCandidate="notSelectCandidate"
                                        @selectCandidate="selectCandidate" 
                                        @onDisplayNew="onDisplayNew" 
                                        @onDisplaySelected='onDisplaySelected'
                                        @onDisplayOut="onDisplayOut" 
                                        @showModal="showModal" 
                                        :pagesPending="pagesPending" 
                                        :currentPagePending="currentPagePending"
                                        :totalPending="totalPending" 
                                        @changePagePending="changePagePending" 
                                        @previousPagePending="previousPagePending"
                                        @nextPagePending="nextPagePending" 
                                        :loadingPending="loadingPending"
                                        :pagesSelected="pagesSelected" 
                                        :currentPageSelected="currentPageSelected"
                                        :totalSelected="totalSelected" 
                                        :loadingSelected="loadingSelected"
                                        :pagesDeleted="pagesDeleted" 
                                        :currentPageDeleted="currentPageDeleted"
                                        :totalDeleted="totalDeleted" 
                                        :loadingDeleted="loadingDeleted">
                                    </candidate-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn include-btn p-1 ml-3 float-right" @click="() => $router.go(-1)" >  <i class="fa fa-times width-23-px"></i>{{ $t('label.voltar') }}</button>
                    </div>                            
                </div>
            </div>

        </div>

    <!-- Modal de Vagas -->

    <div class="modal" id="modalInvite" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aplicar candidatos à uma vaga</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="form-group">
                <label for="">Selecione uma vaga para vincular os candidatos</label>
                <select class="form-control" v-model="job_selected">
                  <option value selected="selected">{{ $t('label.selecione') }}...</option>
                  <option
                    v-for="job in jobs_available"
                    :value="job"
                    :key="job.id"
                  >{{job.role_name}}</option>
                </select>
              </div>              
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="">Selecione uma etapa do processo para vincular os candidatos</label>
                <select class="form-control" v-model="job_stage">
                  <option value selected="selected">{{ $t('label.selecione') }}...</option>
                  <option
                    v-for="job in job_selected.stages"
                    :value="job.id"
                    :key="job.id"
                  >{{job.name}}</option>
                </select>
              </div>
              
            </div>

            
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              v-if="job_selected"
              @click="apply"
            >Aplicar a esta vaga</button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >{{ $t('label.fechar') }}</button>
          </div>
        </div>
      </div>
    </div>
    </div>        
    
</template>

<script>
import JobRecruitmentCandidateDetail from '../JobRecruitment/JobRecruitmentCandidateDetailTalentBank'
import FilterCandidates from '../Filters/FilterCandidatesTalentBank'
import CandidateList from '../Candidates/CandidateListTalentBank'

import swal from 'sweetalert';

export default {
    components: {JobRecruitmentCandidateDetail, FilterCandidates, CandidateList},
    props: [],
    data: () => {
        return {
            job_stage: '',
            job_selected: '',
            jobs_available: [],
            currentId: new Date(),
            loadingPending: false,
            loadingBlacklist: false,
            loadingOfflimits: false,
            loadingSimultaneos: false,
            loadingSelected: false,
            loadingDeleted: false,

            pagesPending: 0,
            pagesBlacklist: 0,
            pagesOfflimits: 0,
            pagesSimultaneos: 0,
            pagesSelected: 0,
            pagesDeleted: 0,

            currentPagePending: 0,
            currentPageBlacklist: 0,
            currentPageOfflimits: 0,
            currentPageSimultaneos: 0,
            currentPageSelected: 0,
            currentPageDeleted: 0,

            totalPending: 0,
            totalBlacklist: 0,
            totalOfflimits: 0,
            totalSimultaneos: 0,
            totalSelected: 0,
            totalDeleted: 0,

            pendingParams: {},
            selectedParams: {},
            deletedParams: {},

            blacklist: '',
            selectedCandidates: '',

            jobObj: {
                company: {
                    about: '',
                    name: ''
                },
                approver: {
                    id: 0,
                    name: '',
                },
                cost_center: {
                    department_name: ''
                },
                approved_at: '',
                approver_observation: '',
                role_name: ''
            },
            job_id: '',
            endPoint: {
                candidate: "/jobCandidate/",
                jobRoles: "/job/",
                iterationEndPoint: '/jobCandidateStageIteration/',
                messageEndPoint: '/message/',
                filterEndPoint: '/filters/'
            },
            candidatesList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            blacklistList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            offLimitsList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            simultaneousList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            selectedCandidatesList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            deletedCandidatesList: [{
                image: '',
                platform: { name: '' },
                skills: [],
                candidate: {
                    fullName: '',
                }
            }],
            checkedItens: [],
            checkalldisplayed: false,
            displayedLength: 0,
            displayedId: 'candidate',
            staredChoosen: '',
            objectivesOptions: [""],
            typeFilter: 1,
            areasOptions: [""],
            graduationsOptions: [""],
            habilitiesOptions: [""],
            languagesOptions: [""],
            experienceCompaniesOptions: [""],
            experiencePositionsOptions: [""],
            jobCandidate: {
                candidate: {
                    fullName: ''
                }
            },
            iterations: [],
            type: {
                blacklist: 'blacklist',
                offlimits: 'offlimits',
                simultaneous: 'simultaneos'
            }
        }
    },
    computed: {
        url_JOB() {
            return this.$store.getters.apiUrl + this.endPoint.jobRoles
        },
        url_CANDIDATE() {
            return this.$store.getters.apiUrl + this.endPoint.candidate
        },
        url_iterationS() {
            return this.$store.getters.apiUrl + this.endPoint.iterationEndPoint
        },
        url_MESSAGE() {
            return this.$store.getters.apiUrl + this.endPoint.messageEndPoint
        },
        url_FILTERS() {
            return this.$store.getters.apiUrl + '/job/' + this.endPoint.filterEndPoint
        }
    },
    mounted: function () {
        this.$root.$on('reload', (item, response) => {
            this.loadRecruitment(item, response);
        })
        this.forceTabToggle()
        $("#humanflowApp").on('click', "button[data-toggle='collapse']", function() {
            
            let elem = $(this).attr('data-target-id')
            if ($(elem).hasClass('show')) { 
                $(elem).removeClass('show');
            }
            else {
                $(elem).addClass('show');
            }
        });        

        this.loadRecruitment()
    },
    methods: {
        anonymizeCandidate(id) {
            console.log(id);

            let self = this;
            swal({
                title: "Deseja anonimizar o registro?",
                text: "Essa operação não pode ser desfeita",
                type: "warning",
                // showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim, deletar!",
                showLoaderOnConfirm: true,
                dangerMode: true,
                buttons: {
                    catch: {
                        text: "Não",
                        value: "cancel",
                        className: "btn-danger"
                    },
                    confirm: {
                        text: "Sim",
                        value: 'confirm'
                    }
                },
            }).then((value) => {

                if (value == 'confirm') {

                    self.$http.post(self.$store.getters.apiUrl + '/jobCandidate/anonymize', {
                        candidate_id: id
                    }).then(() => {
                        self.loadRecruitment()
                        swal('Sucesso', 'Registro anonimizado com sucesso!', 'success');
                    })
                    

                }
            }).catch(error => {
                self.$errorMessage(error)
            })

            
        },
        applyJobs(){
            console.log(this.checkedItens)
            $("#modalInvite").modal();
        },
        apply(){
            const self = this;

            self.$store.dispatch("tooggleLoadModal");
            self.$http
                .post(this.$store.getters.apiUrl + '/job_apply', {
                    job_id: this.job_selected.id,
                    stage: this.job_stage,
                    candidates: this.checkedItens
                })
                .then( () => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$message('success', self.$t('label.atualizadoComSucesso'), self.$t('label.candidatosVinculadosComSucesso'));
                })
                .then(() => {
                    self.loadAll();
                })
                .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                });
        },
        loadRecruitment () {

            this.currentId = new Date();
            this.$('.carousel').carousel();

            this.pendingParams = {
                'status': 1,
                'with': [
                    'stages',
                    'platform',
                    'skills',
                    'courses',
                    'habilities',
                    'languages',
                    'graduations',
                    'experiences',
                    'networks',
                    'cost_center'
                ]
            };

            this.loadJob();
            this.getAvailable();
            this.loadCandidatesPending();
        },
        forceTabToggle() {
            $(".nav-item .nav-link").click(function() {
              $(".nav-item .nav-link").each(function() {
                $(this).removeClass("active show");
              });
              $(this).addClass("active show");
            });
        },
        actionChoosen: function (action) {

            let self = this

            if (this.checkedItens.length !== 0) {
                switch (action) {
                    case 'selected':
                        this.onMovetoSelected();
                        break;
                    case 'out':
                        this.onMovetoOut();
                        break;
                    case 'blacklist':
                        this.onMoveToBlackList();
                        break;
                    case 'offlimits':
                        this.onMoveToOffLimits();
                        break;
                    case 'pending':
                        this.onMoveToPending();
                        break;
                }
            } else {
                self.$message('warning', self.$t('label.mensagem'), self.$t('label.selecioneAoMenosUmRegistro')+".");
            }

        },
        openLink() {
            window.open(this.jobObj.public_link);
        },
        changePagePending(page) {
            this.currentPagePending = page
            this.pendingParams.page = this.currentPagePending
            this.loadCandidatesPending()
        },
        previousPagePending() {

            let self = this
            if (this.currentPagePending != 1) {
                this.currentPagePending = this.currentPagePending - 1
                this.pendingParams.page = this.currentPagePending
                this.loadCandidatesPending()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.primeiraPagina'));
            }
        },
        nextPagePending() {
            let self = this
            if (this.currentPagePending < this.pagesPending) {

                this.currentPagePending = this.currentPagePending + 1
                this.pendingParams.page = this.currentPagePending
                this.loadCandidatesPending()
            } else {
                self.$message('error', self.$t('label.erro'), self.$t('label.ultimaPagina'));
            }
        },

        showSelection() {
            let self = this
            if (this.selectedCandidatesList.length > 0) {
                this.$router.push({
                    path: '/selection/view/' + this.job_id
                });
            } else {
                self.$message('warning', self.$t('label.alerta'), self.$t('label.selecioneAoMenosUmCandidatoParaProsseguir'));
            }
        },
        selectCandidate(id, type) {
            this.checkedItens.push({
                'id': id,
                'type': type
            });
        },
        showModal: function (candidate) {
            this.jobCandidate = candidate;
            this.$("#jobRecruitmentCandidateDetail").modal('show');
        },
        notSelectCandidate(id) {
            this.checkedItens.splice(this.checkedItens.findIndex(item => item.id == id), 1);
        },
        normalizeArraySelected(status) {
            this.updateCandidatesStatus(this.checkedItens, status);
            this.checkalldisplayed = false;
            this.checkedItens = [];
        },
        onMovetoSelected() {
            this.normalizeArraySelected(2, "Selecionados", this.selectedCandidatesList);
            this.displayedId = 'selected';
            this.$("#pills-selecionados-tab").click()
        },
        onMovetoOut() {
            this.normalizeArraySelected(3, "Desclassificados", this.deletedCandidatesList);
            this.displayedId = 'out';
            this.$("#pills-desclassificados-tab").click()
        },
        onMoveToBlackList() {
            this.normalizeArraySelected(4, "Blacklist", this.blacklistList);
        },
        onMoveToOffLimits() {
            this.normalizeArraySelected(5, "Offlimits", this.offLimitsList);
        },
        onMoveToPending() {
            this.normalizeArraySelected(1, "Pendentes", this.candidatesList);
            this.displayedId = 'candidate';
            this.$("#pills-aplicados-tab").click()
        },
        onDisplayNew() {
            // this.loadPendingCandidates();
            this.displayedLength = this.candidatesList.length;
            this.displayedId = 'candidate';
            this.checkalldisplayed = false;
        },
        onDisplaySelected() {
            // this.loadSelectedCandidates();
            this.displayedLength = this.selectedCandidatesList.length;
            this.displayedId = 'selected';
            this.checkalldisplayed = false;
        },
        onDisplayOut() {
            this.displayedLength = this.deletedCandidatesList.length;
            this.displayedId = 'out';
            this.checkalldisplayed = false;
        },
        onDisplayBlackList() {
            // this.loadBlackListCandidates();
            this.displayedLength = this.blacklist.length;
            this.displayedId = 'blacklist';
            this.checkalldisplayed = false;
        },
        onDisplayOff() {
            this.displayedLength = this.offLimitsList.length;
            this.displayedId = 'offlimits';
            this.checkalldisplayed = false;
        },
        onDisplaySimult() {
            this.displayedLength = this.simultaneousList.length;
            this.displayedId = 'simultaneous';
            this.checkalldisplayed = false;
        },

        loadJob() {
            let self = this;

            self.job_id = self.$route.params.id; 
            self.$http.get(self.url_JOB, {
                params: {
                    'with': ['company', 'approver', "cost_center", 'benefits']
                }
            })
                .then(response => { 
                    self.jobObj = response.data;
                }).catch((error) => { 
                    let errors = (error.response) ? error.response.data :  self.$t('label.ocorreuUmErroAoCarregarInformacoesVaga');

                    if (typeof errors === 'object') {
                        let html = '<ul class="errors">';

                        self.$.each(errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });
                        html += '</ul>';
                        errors = html;
                    }

                    self.$message('error', null, errors);
                });
        },

        updateCandidatesStatus(candidates, status) {
            const self = this;
            self.job_id = self.$route.params.id;

            self.$store.dispatch("tooggleLoadModal");
            self.$http
                .post(self.url_CANDIDATE + 'update/selected', {
                    status: status,
                    candidates: candidates
                })
                .then( () => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$message('success', self.$t('label.atualizadoComSucesso'), self.$t('label.candidatosAtualizadosComSucesso'));
                })
                .then(() => {
                    self.loadAll();
                })
                .catch((error) => {
                    self.$store.dispatch("tooggleLoadModal");
                    self.$errorMessage(error);
                });
        },
        loadAll() {
            this.loadCandidatesPending();
        },

        getAvailable(){
            let self = this;

            self.job_id = self.$route.params.id; 
            self.$http.get(this.$store.getters.apiUrl + '/jobs_available', {
                params: { }
            })
                .then(response => { 
                    self.jobs_available = response.data;
                }).catch((error) => { 
                    let errors = (error.response) ? error.response.data :  self.$t('label.ocorreuUmErroAoCarregarInformacoesVaga');

                    if (typeof errors === 'object') {
                        let html = '<ul class="errors">';

                        self.$.each(errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });

                        html += '</ul>';

                        errors = html;
                    }

                    self.$message('error', null, errors);
                });
        },

        loadCandidatesPending() {
            const self = this;
            self.changeloadingPending(true);

            self.job_id = self.$route.params.id;

            let params = {};
            params = {
                ...self.pendingParams, ...{
                    
                    'status': 1,
                    'talent_bank': 0,
                    'page': self.pendingParams.page,
                    'with': [
                        'stages',
                        'platform',
                        'candidate',
                        'candidate.skills',
                        'candidate.courses',
                        'candidate.habilities',
                        'candidate.languages',
                        'candidate.graduations',
                        'candidate.experiences',
                        'candidate.networks'
                    ]
                }
            };

            self.$http
                .get(self.$store.getters.apiUrl + '/jobCandidate/talent_bank', {
                    'params': params
                })
                .then((response) => {
                    self.candidatesList = response.data.data;
                    self.changeloadingPending(false);
                    self.pagesPending = response.data.last_page;
                    self.currentPagePending = response.data.current_page;
                    self.totalPending = response.data.total
                })
                .catch((error) => {
                    self.$errorMessage(error);
                });
        },

        changeloadingPending: function (value) {
            this.loadingPending = value;
        },
        sendMessage: function (header, txt, id) {
            const self = this;
            self.$http
                .post(self.url_MESSAGE +
                    "role/" + self.job_id +
                    "/candidate/" + id, {
                        header: header,
                        text: txt
                    })
                .then( () => {
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.mensagemEnviadaComSucesso'));
                })
                .catch((error) => {
                    self.$errorMessage(error)
                });
        },
        newiteration: function (txt, id) {
            let self = this;

            self.$http
                .post(self.url_iterationS, {
                    'job_candidate_id': id,
                    'content': txt
                })
                .then((response) => {
                    self.$message('success', self.$t('label.sucesso'), self.$t('label.ok'));
                    self.iterations.splice(0, 0, response.data.data);
                })
                .catch((error) => {
                    self.$errorMessage(error);
                });
        },
        filterCandidates(params) {
            this.pendingParams = JSON.parse('{"' + decodeURI(params.replace(/&/g, "\",\"").replace(/=/g, "\":\"")) + '"}');
            this.currentPagePending = 1;
            this.pendingParams.page = this.currentPagePending;
            this.loadCandidatesPending();
        },
        openJobDetailModal() {
            this.$('#jobDetailModal').modal('show');
        },
        goBack() {
            this.$router.push('/jobRoles')
        }
    },
    filters: {
        normalizeStatus: function (status) {
            let self = this
            switch (status) {
                case 'finished':
                    status = self.$t('label.finalizado');
                    break;
                case 'inedition':
                    status = self.$t('label.emEdicao');
                    break;
                case 'inprogress':
                    status = self.$t('label.emRecrutamento');
                    break;
                case 'canceled':
                    status = self.$t('label.cancelado');
                    break;
            }

            return status;
        }
    }
}
</script>

<style scoped>

.btn {
    margin-right: 5px;
}

</style>